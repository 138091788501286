<template>
  <div class="vms-pagination">
    <div
      v-if="canUpdatePaginationLimit"
      class="vms-pagination__limit--wrapper vd-display-inline vd-margin-right-small"
    >
      <span v-if="!isExtraSmallScreen" class="vd-light-blue">Show: </span>
      <div
        v-click-outside="handleHidePaginationLimit"
        class="vms-pagination__limit vd-display-inline vd-border-grey vd-padding-extra-small vd-padding-right-medium"
      >
        <span
          id="showPaginationLimitOptions"
          @click="handlePaginationLimitClick"
        >
          <span class="vd-black">{{ resultsLimit }}</span>
          <span
            class="vd-icon-arrow-down-grey-black vms-pagination__limit--icon vd-margin-left-small"
          ></span>
        </span>
        <span v-if="showLimitOptions" class="vd-display-inline-block">
          <span
            class="vms-dropdown vd-box-shadow vd-background-white vd-border-radius-6"
          >
            <span
              v-for="(limitOption, index) in limitOptions"
              :id="`paginationLimit${limitOption}`"
              :key="index"
              class="vms-dropdown__list vd-display-block vd-black vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium"
              @click="handleLimitOptionSelect(limitOption)"
            >
              {{ limitOption }}
            </span>
          </span>
        </span>
      </div>
    </div>
    <b-pagination
      :value="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      :limit="limit"
      :size="size"
      @change="currentPageUpdate"
    ></b-pagination>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapGetters } from 'vuex'
import { BPagination } from 'bootstrap-vue'
import { includes } from 'lodash'

export default {
  components: {
    BPagination,
  },

  directives: {
    ClickOutside,
  },

  props: {
    totalRows: {
      type: Number,
      required: true,
      default: 0,
    },

    perPage: {
      type: Number,
      required: false,
      default: 10,
    },

    limit: {
      type: Number,
      required: false,
      default: 5,
    },

    size: {
      type: String,
      required: false,
      default: 'sm',
    },

    showPageInRoute: {
      type: Boolean,
      required: false,
      default: true,
    },

    limitOptions: {
      type: Array,
      required: false,
      default: () => {
        return [10, 25, 50, 'All']
      },
    },

    page: {
      type: [String, Number],
      required: false,
      default: 1,
    },

    canUpdatePaginationLimit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      currentPage: 1,
      showLimitOptions: false,
      resultsLimit: 10,
    }
  },

  computed: {
    ...mapGetters({
      windowWidth: 'common/windowWidth',
    }),

    selectedPage() {
      return this.$route.query?.page || this.page
    },

    isExtraSmallScreen() {
      return this.windowWidth < 400
    },
  },

  watch: {
    selectedPage(val) {
      this.currentPage = val
    },

    perPage() {
      this.resultsLimitUpdate()
    },
  },

  beforeMount() {
    if (this.selectedPage > 1) {
      this.currentPageUpdate(this.selectedPage)
    }

    this.resultsLimitUpdate()
  },

  methods: {
    resultsLimitUpdate() {
      this.resultsLimit = includes(this.limitOptions, this.perPage)
        ? this.perPage
        : 'All'
    },

    currentPageUpdate(page) {
      this.currentPage = page

      // only replace the page query if the new page is different from the currently selected page
      // and if showPageInRoute is true
      if (this.selectedPage !== page && this.showPageInRoute) {
        this.$router.replace({
          query: {
            page: page,
          },
        })
      }

      this.$emit('update:page', page)
    },

    handlePaginationLimitClick() {
      this.showLimitOptions = !this.showLimitOptions
    },

    handleHidePaginationLimit() {
      this.showLimitOptions = false
    },

    handleLimitOptionSelect(limit) {
      this.resultsLimit = limit
      this.showLimitOptions = false

      const perPage = limit === 'All' ? this.totalRows : limit

      this.$emit('update:per_page', perPage)
    },
  },
}
</script>
