<template>
  <div>
    <div v-if="!shouldUsePopover">
      {{ officeName | truncateString(maxOfficeLength) }}
    </div>

    <text-popover
      v-else
      :tooltip-id="`officeNameTooltip-${projectId}`"
      custom-tooltip-class="vd-padding-small vd-margin-top-small"
      tooltip-trigger="hover"
      tooltip-placement="right"
      :tooltip-text="officeName"
      :show-icon="false"
      :tooltip-label="tooltipLabel"
    >
    </text-popover>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import TextPopover from '@components/tooltips/text-popover'
import { size } from 'lodash'

export default {
  components: {
    TextPopover,
  },

  mixins: [FiltersMixin],

  props: {
    officeName: {
      type: String,
      required: false,
      default: '',
    },

    projectId: {
      type: Number,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      maxOfficeLength: 60,
    }
  },

  computed: {
    shouldUsePopover() {
      return size(this.officeName) > this.maxOfficeLength
    },

    tooltipLabel() {
      return `<div>${this.$options.filters.truncateString(
        this.officeName,
        this.maxOfficeLength
      )}</div>`
    },
  },
}
</script>
