<template>
  <div class="row no-gutters">
    <div class="col-6">
      <router-link
        :to="{
          name: 'project.details.summary',
          params: { projectId: project.id },
        }"
      >
        <text-popover
          custom-tooltip-class="vms-projects__list--actions-icon"
          tooltip-text="View project details"
          tooltip-icon="vd-icon-eye"
          :tooltip-id="`viewProjectDetailsIcon-${project.id}`"
        ></text-popover>
      </router-link>
    </div>
    <div class="col-4 offset-2">
      <div class="vms-projects__list--menu__container">
        <text-popover
          custom-tooltip-class="vms-projects__list--actions-icon"
          tooltip-icon="vd-icon-three-dot"
          :tooltip-id="`divProjectThreeDotMenu-${project.id}`"
          tooltip-trigger="click blur"
          popover-custom-class="vms-projects__list--menu__dropdown"
          :boundary-padding="1"
          :tooltip-placement="'leftbottom'"
        >
          <projects-list-actions-dropdown
            slot="popover-body"
            :project="project"
          >
          </projects-list-actions-dropdown>
        </text-popover>
      </div>
    </div>
  </div>
</template>

<script>
import TextPopover from '@components/tooltips/text-popover'
import ProjectsListActionsDropdown from '@views/projects/projects-list/projects-list-row/projects-list-actions/projects-list-actions-dropdown'

export default {
  components: {
    TextPopover,
    ProjectsListActionsDropdown,
  },

  props: {
    project: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
}
</script>
