<template>
  <div :class="customParentDivClass">
    <div id="archiveProjectDiv" @click="handleAction">
      <slot name="body"></slot>
    </div>
    <archive-project-modal
      v-if="isArchiveProjectModalShown"
      :project="project"
      :update-project-list="updateProjectList"
    >
    </archive-project-modal>
  </div>
</template>

<script>
import SweetAlert from '@plugins/sweet-alert'
import ArchiveProjectModal from '@components/archive-project/archive-project-modal'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    ArchiveProjectModal,
  },

  props: {
    project: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    customParentDivClass: {
      type: String,
      required: false,
      default: 'vd-display-inline-block',
    },

    updateProjectList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showArchiveModal: false,
    }
  },

  computed: {
    ...mapGetters({
      isArchiveProjectModalShown: 'project/isArchiveProjectModalShown',
    }),

    canArchiveProject() {
      return (
        this.project.total_video === 0 || this.project.total_hero_videos > 0
      )
    },

    alertContent() {
      const url = this.$router.resolve({
        name: 'project.details.deliveries',
        params: { projectId: this.project.id },
      }).href

      return `
        <div class="vd-margin-top-small">Sorry, you can not archive this project because at least one hero video should be assigned.</div>
        <div class="vd-margin-top-small">Click <a class="vd-a" style="font-size: inherit;" href="${url}">here</a> to go to delivery page.</div>`
    },
  },

  methods: {
    ...mapActions({
      toggleArchiveProjectModal: 'project/toggleArchiveProjectModal',
    }),

    handleAction() {
      if (this.canArchiveProject) {
        this.toggleArchiveProjectModal(true)
        return
      }

      SweetAlert.fire({
        title: 'Project Archive',
        html: this.alertContent,
        showCancelButton: true,
        cancelButtonText: 'Close',
        showConfirmButton: false,
        confirmButtonText: 'Archive Project',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
        scrollbarPadding: false,
      })
    },
  },
}
</script>
