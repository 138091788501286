<template>
  <div
    :class="[
      'vd-border-radius-100 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-text-align-center',
      projectStatusClass.container,
    ]"
  >
    <span :class="projectStatusClass.label">
      {{ projectStatus.name }}
    </span>
  </div>
</template>

<script>
import StylesMixin from '@mixins/styles-mixin'

export default {
  mixins: [StylesMixin],

  props: {
    projectStatus: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    projectStatusClass() {
      return this.getStatusClass('projectStatus', this.projectStatus.id)
    },
  },
}
</script>
