<template>
  <div class="vd-background-navy container-fluid">
    <div class="vms-header vd-display-flex vd-align-center">
      <i
        id="hamburgerMenuDiv"
        class="vd-icon icon-bars vd-h3 vd-cursor-pointer vd-hover-light-grey vd-white vd-margin-right-medium"
        @click="handleToggleSideMenu"
      ></i>

      <a :href="getVmsUrl" class="vd-margin-right-auto">
        <span :class="['vms-header__logo', logoClass]"></span>
      </a>

      <a
        class="vd-margin-right-medium vd-text-decoration-none"
        href="https://visdom.atlassian.net/wiki/spaces/VMSTD/overview"
        target="_blank"
      >
        <i class="vd-icon icon-circle-question vd-white vd-h2"></i>
      </a>

      <div
        class="vms-header__user vd-padding-left-medium vd-display-flex vd-align-center vd-border-darker-grey vd-border-width-0 vd-border-left-width-1"
      >
        <a :href="getVmsProfileUrl">
          <user-profile
            :user="vmsUser"
            custom-image-class="vms-header__user--avatar"
            custom-avatar-class="vms-header__user--avatar"
            :custom-avatar-font-size="45"
          ></user-profile>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppConfig from '@configs/app-config'
import UserProfile from '@components/user-profile/user-profile'

export default {
  components: {
    UserProfile,
  },

  computed: {
    ...mapGetters({
      vmsUser: 'auth/getVmsUserData',
      windowWidth: 'common/windowWidth',
      isMobileScreen: 'common/isMobileScreen',
    }),

    getVmsUrl() {
      return `${AppConfig.vmsApiUrl}`
    },

    getVmsProfileUrl() {
      return `${AppConfig.vmsApiUrl}/users/profile`
    },

    logoClass() {
      return !this.isMobileScreen
        ? 'vd-logo-vms'
        : 'vd-logo-shield vms-header__logo--desktop'
    },
  },

  methods: {
    ...mapActions({
      toggleSideMenu: 'common/toggleSideMenu',
      setSelectedSideMenu: 'common/setSelectedSideMenu',
    }),

    handleToggleSideMenu() {
      this.toggleSideMenu()
      this.setSelectedSideMenu(null)
    },
  },
}
</script>

<style lang="scss">
@import '@styles/layouts/header';
</style>
