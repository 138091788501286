<template>
  <div>
    <v-edit-dialog
      ref="dateDurationInlineDialog"
      @save="handleSave"
      @cancel="handleCancel"
      @close="handleClose"
    >
      <slot name="inline-body"></slot>

      <template v-slot:input>
        <slot name="inline-body-header"></slot>

        <div class="row no-gutters vms-projects__datepicker">
          <div class="vms-projects__datepicker--start">
            <div
              class="vd-padding-top-small vd-padding-right-small vd-padding-left-medium vd-background-aqua vd-black"
            >
              {{ startDateLabel }}
            </div>
            <v-date-picker
              v-model="startDate"
              :show-current="startDate"
              :color="datePickerColour"
              :header-color="datePickerHeaderColour"
              :width="datePickerWidth"
            ></v-date-picker>
          </div>

          <div class="vms-projects__datepicker--end">
            <div
              class="vd-padding-top-small vd-padding-right-small vd-padding-left-medium vd-background-aqua vd-black"
            >
              {{ endDateLabel }}
            </div>
            <v-date-picker
              v-model="endDate"
              :show-current="endDate"
              :color="datePickerColour"
              :header-color="datePickerHeaderColour"
              :width="datePickerWidth"
            ></v-date-picker>
          </div>
        </div>

        <div class="vd-padding-medium vd-text-align-right">
          <button
            class="vd-btn-grey vd-btn-small vd-margin-right-small"
            @click="handleCancelBtn"
          >
            Cancel
          </button>
          <button class="vd-btn-blue vd-btn-small" @click="handleSave">
            Save
          </button>
        </div>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import FormMixin from '@mixins/forms-mixin'
import momentTz from 'moment-timezone'

export default {
  mixins: [FormMixin],

  props: {
    defaultStartDate: {
      type: Number,
      required: false,
      default: null,
    },

    defaultEndDate: {
      type: Number,
      required: false,
      default: null,
    },

    startDateLabel: {
      type: String,
      required: false,
      default: 'Start Date',
    },

    endDateLabel: {
      type: String,
      required: false,
      default: 'Due Date',
    },

    datePickerColour: {
      type: String,
      required: false,
      default: '#00c5ff',
    },

    datePickerHeaderColour: {
      type: String,
      required: false,
      default: '#dbf3fc',
    },

    datePickerWidth: {
      type: Number,
      required: false,
      default: 250,
    },
  },

  data() {
    return {
      startDate: null,
      endDate: null,
    }
  },

  mounted() {
    this.initDefaultDates()
  },

  methods: {
    initDefaultDates() {
      if (this.defaultStartDate) {
        this.startDate = momentTz
          .unix(this.defaultStartDate)
          .format('YYYY-MM-DD')
      } else {
        this.startDate = momentTz().format('YYYY-MM-DD')
      }

      if (this.defaultEndDate) {
        this.endDate = momentTz.unix(this.defaultEndDate).format('YYYY-MM-DD')
      } else {
        this.endDate = momentTz().format('YYYY-MM-DD')
      }
    },

    handleSave() {
      // Force dialog to close using the save function
      this.$refs.dateDurationInlineDialog.save()

      this.$emit('handle:inline-date-save', {
        start_date: this.startDate,
        end_date: this.endDate,
      })
    },

    handleCancelBtn() {
      // Force dialog to close using the cancel function
      this.$refs.dateDurationInlineDialog.cancel()
    },

    handleCancel() {
      this.initDefaultDates()

      this.$emit('handle:inline-date-cancel', {
        start_date: this.startDate,
        end_date: this.endDate,
      })
    },

    handleClose() {
      this.initDefaultDates()

      this.$emit('handle:inline-date-close', {
        start_date: this.startDate,
        end_date: this.endDate,
      })
    },
  },
}
</script>
