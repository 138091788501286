<template>
  <filters-dropdown
    class="vd-margin-right-medium vd-padding-bottom-extra-small vd-display-inline-block"
    :custom-active-class="dropdownActiveClass"
    :custom-dropdown-list-class="customDropdownListClass"
    :hide-selected-text="hideSelectedText"
    :selected-text="selectedUserName"
    :has-action-buttons="true"
    @handle:cancel-click="handleCancelClick"
    @handle:apply-click="handleUsersSelect"
  >
    <template slot="filter-icon">
      <user-profile
        v-if="selectedUser"
        :user="selectedUser"
        class="vd-margin-right-small vms-filters__user--profile"
        :custom-avatar-font-size="25"
      ></user-profile>

      <i
        v-else
        slot="filter-icon"
        :class="[iconColor, 'vd-icon icon-circle-user vd-margin-right-small']"
      >
      </i>
    </template>

    <div slot="filter-dropdown-header" class="vd-padding-small">
      <input
        v-model="userSearch"
        class="vd-border-light-grey vd-padding-small vd-border-radius-6 vms-projects__filters--users-search"
        type="text"
        placeholder="Search user"
      />
    </div>

    <div slot="filter-dropdown-list">
      <div
        v-for="(user, index) in usersListSorted"
        :key="index"
        class="vd-padding-small vms-filters__dropdown--list-options"
      >
        <label :for="`userFilterCheckbox-${index}`">
          <input
            :id="`userFilterCheckbox-${index}`"
            v-model="selectedUsers"
            type="checkbox"
            class="vd-margin-right-small"
            :value="user.id"
          />

          <user-profile
            :user="user"
            class="vd-margin-right-small vms-filters__align-middle"
            custom-image-class="vms-filters__user--profile"
            :custom-avatar-font-size="25"
          ></user-profile>
          <span>{{ user.full_name }}</span>
        </label>
      </div>
    </div>
  </filters-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersDropdown from '@components/filters/filter-dropdown'
import UserProfile from '@components/user-profile/user-profile'
import FiltersMixin from '@mixins/filters-mixin'
import {
  forEach,
  size,
  isNil,
  find,
  isEmpty,
  filter,
  includes,
  toLower,
  sortBy,
} from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    FiltersDropdown,
    UserProfile,
  },

  mixins: [FiltersMixin],

  props: {
    hideSelectedText: {
      type: Boolean,
      required: false,
      default: false,
    },

    customActiveClass: {
      type: String,
      required: false,
      default: '',
    },

    customDropdownListClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      selectedUsers: [],
      maxNameLength: 18,
      userSearch: '',
      usersList: [],
    }
  },

  computed: {
    ...mapGetters({
      selectedUserFilter: 'projects/selectedUserFilter',
      staffList: 'user/staffList',
    }),

    selectedUser() {
      return size(this.selectedUserFilter.users) === 1
        ? this.selectedUserFilter.users[0]
        : null
    },

    selectedUserName() {
      if (this.selectedUser) {
        return `${this.$options.filters.truncateString(
          this.selectedUserFilter.users[0].full_name,
          this.maxNameLength
        )}`
      }

      return this.selectedUserFilter.text
    },

    usersListSorted() {
      return sortBy(this.usersList, 'first_name')
    },

    iconColor() {
      return this.customActiveClass && size(this.selectedUserFilter.users) > 0
        ? 'vd-white'
        : 'vd-black'
    },

    dropdownActiveClass() {
      return this.customActiveClass && size(this.selectedUserFilter.users) > 0
        ? this.customActiveClass
        : ''
    },
  },

  watch: {
    userSearch() {
      if (this.userSearch !== '') {
        this.usersList = filter(this.staffList, (user) => {
          return includes(toLower(user.full_name), toLower(this.userSearch))
        })
      } else {
        this.usersList = this.staffList
      }
    },
  },

  mounted() {
    this.usersList = this.staffList

    if (!isEmpty(this.selectedUserFilter.users)) {
      forEach(this.selectedUserFilter.users, (user) => {
        this.selectedUsers.push(user.id)
      })
    }
  },

  methods: {
    ...mapActions({
      getAllProjects: 'projects/getAllProjects',
      updateProjectUserFilter: 'projects/updateProjectUserFilter',
      updateProjectTypeFilter: 'projects/updateProjectTypeFilter',
    }),

    handleCancelClick() {
      this.selectedUsers = []
      this.userSearch = ''

      forEach(this.selectedUserFilter.users, (user) => {
        this.selectedUsers.push(user.id)
      })
    },

    handleUsersSelect() {
      const usersSelected = []
      const selectedUserIds = []
      this.userSearch = ''

      forEach(this.selectedUsers, (selectedUserId) => {
        const user = find(this.staffList, (user) => {
          return user.id === selectedUserId
        })

        if (!isNil(user)) {
          usersSelected.push(user)
          selectedUserIds.push(user.id)
        }
      })

      this.updateProjectUserFilter(usersSelected)
      this.updateProjectTypeFilter('all_projects')

      this.getAllProjects({
        include: ApiCustomIncludes.projectsList,
        filter: {
          users: selectedUserIds.join(','),
        },
        page: 1,
      })

      this.$emit('handle:filter-select', {
        type: 'users',
        value: selectedUserIds,
      })
    },
  },
}
</script>
