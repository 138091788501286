<template>
  <div class="vd-text-align-center">
    <span class="vd-margin-right-small">
      <v-progress-circular
        :size="loaderSize"
        :width="loaderWidth"
        indeterminate
        :color="loaderColour"
      ></v-progress-circular>
    </span>
    <span>{{ loadingText }}</span>
  </div>
</template>

<script>
export default {
  props: {
    loadingText: {
      type: String,
      required: false,
      default: 'Saving...',
    },

    loaderColour: {
      type: String,
      required: false,
      default: 'primary',
    },

    loaderWidth: {
      type: Number,
      required: false,
      default: 3,
    },

    loaderSize: {
      type: Number,
      required: false,
      default: 20,
    },
  },
}
</script>
