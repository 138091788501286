<template>
  <div
    :class="[
      'vms-projects-empty',
      isMobileScreen ? 'vd-margin-top-medium' : 'vd-margin-medium',
    ]"
  >
    <div
      :class="[
        'vms-projects-empty__content vd-text-align-center ',
        isMobileScreen
          ? 'vms-projects-empty__content--mobile'
          : 'vd-position-absolute vd-padding-medium',
      ]"
    >
      <div class="vd-margin-bottom-large">{{ description }}</div>
      <img :src="imageSrc" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    imageSrc: {
      type: String,
      required: false,
      default: '/images/empty-states/empty-projects-list.svg',
    },

    description: {
      type: String,
      required: false,
      default: 'Nope! Nothing down here...',
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),
  },
}
</script>

<style lang="scss">
@import '@styles/components/projects-list-no-results';
</style>
