<template>
  <div
    v-if="canShowSideMenu"
    v-click-outside="handleSideMenuClickOutside"
    :class="[
      'vms-side-menu vd-border-light-grey vd-border-width-0 vd-border-right-width-2',
      isSideMenuOpen || isMobileScreen ? 'vms-side-menu__opened' : '',
      isMobileScreen ? mobileMenuClass : 'vd-box-shadow',
    ]"
  >
    <side-menu-list
      v-for="(menu, index) in sideMenuList"
      :key="index"
      :side-menu="menu"
    ></side-menu-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SideMenuList from '@layouts/side-menu/components/side-menu-list'
import PermissionsMixin from '@mixins/permissions-mixin'

export default {
  components: {
    SideMenuList,
  },

  mixins: [PermissionsMixin],

  data() {
    return {
      preventClickOutside: true,
    }
  },

  computed: {
    ...mapGetters({
      isSideMenuOpen: 'common/isSideMenuOpen',
      isMobileScreen: 'common/isMobileScreen',
    }),

    projectsMenu() {
      return {
        id: 'projects',
        label: 'Projects',
        icon: 'icon-folder-video',
        route: {
          name: 'projects.list',
          isExternal: false,
        },
        hasSubMenu: false,
        hasPermission: this.can('manage_projects'),
      }
    },

    creditsMenu() {
      return {
        id: 'credits',
        label: 'Credits',
        icon: 'icon-credits',
        hasPermission: this.canDisplayCreditsMenu,
        hasSubMenu: true,
        subMenuList: [
          {
            label: 'Draft credits orders',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/orders/draft`,
              isExternal: true,
            },
            hasPermission: this.can('manage_orders'),
          },
          {
            label: 'Credit orders',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/orders/credit`,
              isExternal: true,
            },
            hasPermission: this.can('manage_orders'),
          },
          {
            label: 'Cancelled orders',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/orders/credit-cancelled`,
              isExternal: true,
            },
            hasPermission: this.can('manage_orders'),
          },
          {
            label: 'Archived orders',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/orders/credit-archived`,
              isExternal: true,
            },
            hasPermission: this.can('manage_orders'),
          },
        ],
      }
    },

    videoLibraryMenu() {
      return {
        id: 'video-library',
        label: 'Video library',
        icon: 'icon-square-caret-right-folders',
        route: {
          name: 'videos.library',
          isExternal: false,
        },
        hasSubMenu: false,
        hasPermission: true,
      }
    },

    calendarMenu() {
      return {
        id: 'calendar',
        label: 'Calendar',
        icon: 'icon-calendar-days',
        route: {
          name: 'calendar.index',
          isExternal: false,
        },
        hasSubMenu: false,
        hasPermission: this.can('view_calendar'),
      }
    },

    clientsMenu() {
      return {
        id: 'clients',
        label: 'Clients',
        icon: 'icon-square-user-between-lines',
        hasPermission: this.canDisplayClientsMenu,
        hasSubMenu: true,
        subMenuList: [
          {
            label: 'Companies',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/companies`,
              isExternal: true,
            },
            hasPermission: this.can('manage_companies'),
          },
          {
            label: 'Offices',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/offices`,
              isExternal: true,
            },
            hasPermission: this.can('manage_offices'),
          },
          {
            label: 'Contacts',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/users/client-dashboard`,
              isExternal: true,
            },
            hasPermission: this.can('manage_contacts'),
          },
        ],
      }
    },

    accountManagementMenu() {
      return {
        id: 'account-management',
        label: 'Account management',
        icon: 'icon-user-gear',
        hasPermission: this.canDisplayAccountManagementMenu,
        hasSubMenu: true,
        subMenuList: [
          {
            label: 'VMS users',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/users`,
              isExternal: true,
            },
            hasPermission: this.can('manage_users'),
          },
          {
            label: 'Teams',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/teams`,
              isExternal: true,
            },
            hasPermission: this.can('manage_teams'),
          },
          {
            label: 'Crew ratings',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/crew-ratings`,
              isExternal: true,
            },
            hasPermission: this.can('manage_crew_rating'),
          },
          {
            label: 'Registration requests',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/user-registration-requests`,
              isExternal: true,
            },
            hasPermission: this.can('manage_user_registration_requests'),
          },
          {
            label: 'Credit tracking',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/analytics/credits-report`,
              isExternal: true,
            },
            hasPermission: this.can('view_credits_report'),
          },
          {
            label: 'Media manager dashboard',
            route: {
              name: 'media-manager.list',
              isExternal: false,
            },
            hasPermission: this.can('check_shoot_asset'),
          },
        ],
      }
    },

    analyticsMenu() {
      return {
        id: 'analytics',
        label: 'Analytics',
        icon: 'icon-square-poll-vertical',
        hasPermission: this.canDisplayAnalyticsMenu,
        hasSubMenu: true,
        subMenuList: [
          {
            label: 'Daily performance',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/analytics/daily-performance-report`,
              isExternal: true,
            },
            hasPermission: this.can('view_daily_performance_report'),
          },
          {
            label: 'Sales report',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/analytics/visual-domain-sales-report`,
              isExternal: true,
            },
            hasPermission: this.can('view_house_accounts_report'),
          },
          {
            label: 'Accounts credits',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/analytics/accounts-credits-report`,
              isExternal: true,
            },
            hasPermission: this.can('view_accounts_credits_report'),
          },
          {
            label: 'Monthly credit used',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/analytics/monthly-credit-used-report`,
              isExternal: true,
            },
            hasPermission: this.can('view_monthly_credit_used_report'),
          },
          {
            label: 'Sales commission',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/analytics/sales-commission-report`,
              isExternal: true,
            },
            hasPermission: this.can('view_sales_commission_report'),
          },
          {
            label: 'Revenue report',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/analytics/revenue-report`,
              isExternal: true,
            },
            hasPermission: this.can('view_revenue_report'),
          },
          {
            label: 'Profitability performance',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/analytics/timesheet-report`,
              isExternal: true,
            },
            hasPermission: this.can('view_timesheet_report'),
          },
          {
            label: 'Producer BAMs',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/analytics/producer-bams-report`,
              isExternal: true,
            },
            hasPermission: this.can('view_producer_bams_report'),
          },
        ],
      }
    },

    adminMenu() {
      return {
        id: 'admin',
        label: 'Admin',
        icon: 'icon-shield-user-circle',
        hasPermission: this.canDisplayAdminMenu,
        hasSubMenu: true,
        subMenuList: [
          {
            label: 'Permissions',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/permissions`,
              isExternal: true,
            },
            hasPermission: this.can('manage_permissions'),
          },
          {
            label: 'Products',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/products`,
              isExternal: true,
            },
            hasPermission: this.can('manage_products'),
          },
          {
            label: 'Product control',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/settings/product-control-settings`,
              isExternal: true,
            },
            hasPermission: this.can('settings'),
          },
          {
            label: 'Sliders',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/sliders`,
              isExternal: true,
            },
            hasPermission: this.can('manage_sliders'),
          },
          {
            label: 'General settings',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/settings`,
              isExternal: true,
            },
            hasPermission: this.can('settings'),
          },
          {
            label: 'API clients',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/admin/tasks/api-clients`,
              isExternal: true,
            },
            hasPermission: this.can('manage_api_clients'),
          },
          {
            label: 'Xero API',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/admin/tasks/xero-api`,
              isExternal: true,
            },
            hasPermission: this.can('manage_api_clients'),
          },
          {
            label: 'Hubspot API',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/admin/tasks/hubspot-api`,
              isExternal: true,
            },
            hasPermission: this.can('manage_api_clients'),
          },
          {
            label: 'Google drive API',
            route: {
              url: `${process.env.VUE_APP_VMS_URL}/admin/tasks/google-drive-api`,
              isExternal: true,
            },
            hasPermission: this.can('manage_api_clients'),
          },
        ],
      }
    },

    sideMenuList() {
      return [
        this.projectsMenu,
        this.creditsMenu,
        this.videoLibraryMenu,
        this.calendarMenu,
        this.clientsMenu,
        this.accountManagementMenu,
        this.analyticsMenu,
        this.adminMenu,
      ]
    },

    canShowSideMenu() {
      return !this.isMobileScreen || this.isSideMenuOpen
    },

    mobileMenuClass() {
      if (
        this.$route.name === 'projects.list' ||
        this.$route.name === 'videos.library' ||
        this.$route.name === 'video.details' ||
        this.$route.name === 'calendar.index'
      ) {
        return 'vms-side-menu__mobile--full-height'
      } else {
        return 'vms-side-menu__mobile'
      }
    },

    canDisplayCreditsMenu() {
      return this.can('manage_orders')
    },

    canDisplayClientsMenu() {
      return (
        this.can('manage_companies') ||
        this.can('manage_offices') ||
        this.can('manage_contacts')
      )
    },

    canDisplayAccountManagementMenu() {
      return (
        this.can('manage_users') ||
        this.can('manage_teams') ||
        this.can('manage_crew_rating') ||
        this.can('manage_user_registration_requests') ||
        this.can('view_credits_report')
      )
    },

    canDisplayAnalyticsMenu() {
      return (
        this.can('view_daily_performance_report') ||
        this.can('view_house_accounts_sales_report') ||
        this.can('view_accounts_credits_report') ||
        this.can('view_monthly_credit_used_report') ||
        this.can('view_sales_commission_report') ||
        this.can('view_revenue_report') ||
        this.can('view_timesheet_report') ||
        this.can('view_producer_bams_report')
      )
    },

    canDisplayAdminMenu() {
      return (
        this.can('manage_permissions') ||
        this.can('manage_products') ||
        this.can('settings') ||
        this.can('manage_sliders') ||
        this.can('manage_api_clients')
      )
    },
  },

  methods: {
    ...mapActions({
      toggleSideMenu: 'common/toggleSideMenu',
      setSelectedSideMenu: 'common/setSelectedSideMenu',
    }),

    handleSideMenuClickOutside(obj) {
      // Only toggle side menu if it's open and user is not clicking the hamburger menu.
      // Currently hamburger menu is considered clicked outside due to its location (outside the menu)
      // Hamburger menu has its own function to toggle, so we don't want to toggle it here.
      if (this.isSideMenuOpen && obj.target.id !== 'hamburgerMenuDiv') {
        this.toggleSideMenu()

        // reset the selected side menu to prevent double click on the same menu item after clicking outside
        this.setSelectedSideMenu(null)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/v2/layouts/side-menu';
</style>
