<template>
  <div :class="customParentDivClass">
    <div id="showProjectHarvestDiv" @click="handleHarvestIconClick">
      <slot name="body"></slot>
    </div>

    <!-- harvest element -->
    <div
      ref="harvestTimerButton"
      :class="harvestTimerClass"
      :data-item="harvestDataItem"
      :data-default="harvestDataDefault"
    ></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppConfig from '@configs/app-config'
import { isNil } from 'lodash'

export default {
  props: {
    project: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    customParentDivClass: {
      type: String,
      required: false,
      default: 'vd-display-inline-block',
    },

    projectFirstProductionOrder: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      harvestButtonReady: false,
    }
  },

  computed: {
    firstProductionOrder() {
      return this.project.project_orders
        ? this.project.project_orders[0]
        : this.projectFirstProductionOrder
    },

    harvestDataItem() {
      return this.harvestButtonReady
        ? JSON.stringify({
            id: this.firstProductionOrder.id,
            name: this.firstProductionOrder.name,
          })
        : null
    },

    harvestDataDefault() {
      return this.harvestButtonReady
        ? JSON.stringify({ project_code: this.firstProductionOrder.id })
        : null
    },

    harvestPlatformConfig() {
      return {
        applicationName: 'VMSX',
        skipStyling: true,
        permalink: `${AppConfig.vmsApiUrl}/orders/${this.firstProductionOrder.id}`,
      }
    },

    harvestTimerClass() {
      return `harvest-timer-${this.project.id}`
    },
  },

  methods: {
    ...mapActions({
      displayErrorMessage: 'common/displayErrorMessage',
      bugsnagNotify: 'common/bugsnagNotify',
    }),

    activateHarvest() {
      this.$nextTick(() => {
        window._harvestPlatformConfig = this.harvestPlatformConfig

        // Refresh the event for harvest-timer to reload with new attributes.
        const event = new CustomEvent('harvest-event:timers:add', {
          detail: {
            element: document.querySelector(`.${this.harvestTimerClass}`),
          },
        })
        document.querySelector('#harvest-messaging').dispatchEvent(event)

        // Fire the click to proceed now.
        const elem = this.$refs.harvestTimerButton
        elem.click()
      })
    },

    handleHarvestIconClick() {
      if (isNil(this.firstProductionOrder)) {
        this.displayErrorMessage({
          message: 'No production order for the selected project.',
          title: 'Trying to use Harvest tracking \n with no production order.',
        })

        this.bugsnagNotify({
          title: 'Trying to use Harvest tracking with no production order.',
          severity: 'error',
          error: 'No production order for the selected project.',
          payload: {
            project_id: this.project.id,
          },
        })

        return
      }

      // update harvestButtonReady state
      const harvestOverlay = document.querySelector('.harvest-overlay')
      this.harvestButtonReady = !!harvestOverlay

      if (this.harvestButtonReady) {
        this.harvestButtonReady = false
        harvestOverlay.click()
        return
      }

      this.harvestButtonReady = true
      this.activateHarvest()
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/harvest';
</style>
