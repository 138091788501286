<template>
  <filters-dropdown
    class="vd-margin-right-medium vd-padding-bottom-extra-small vd-display-inline-block"
    :custom-active-class="dropdownActiveClass"
    :hide-selected-text="hideSelectedText"
    :selected-text="selectedText"
    :dropdown-options="dropdownOptions"
    :custom-dropdown-list-class="dropdownListClass"
    @handle:option-select="handleProjectTypeUpdate"
  >
    <i
      slot="filter-icon"
      :class="[iconColor, 'vd-icon icon-folder-video vd-margin-right-small']"
    ></i>
  </filters-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersDropdown from '@components/filters/filter-dropdown'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    FiltersDropdown,
  },

  props: {
    hideSelectedText: {
      type: Boolean,
      required: false,
      default: false,
    },

    customActiveClass: {
      type: String,
      required: false,
      default: '',
    },

    customDropdownListClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    ...mapGetters({
      isMyProjectFilterSelected: 'projects/isMyProjectFilterSelected',
    }),

    dropdownOptions() {
      return [
        {
          value: 'all_projects',
          text: 'All Projects',
        },
        {
          value: 'my_projects',
          text: 'My Projects',
        },
      ]
    },

    selectedText() {
      return this.isMyProjectFilterSelected ? 'My Projects' : 'All Projects'
    },

    iconColor() {
      return this.customActiveClass && this.isMyProjectFilterSelected
        ? 'vd-white'
        : 'vd-black'
    },

    dropdownActiveClass() {
      return this.isMyProjectFilterSelected ? this.customActiveClass : ''
    },

    dropdownListClass() {
      return `vms-projects__filters--project-type ${this.customDropdownListClass}`
    },
  },

  methods: {
    ...mapActions({
      getAllProjects: 'projects/getAllProjects',
      updateProjectTypeFilter: 'projects/updateProjectTypeFilter',
    }),

    handleProjectTypeUpdate(option) {
      this.updateProjectTypeFilter(option.value)

      this.getAllProjects({
        include: ApiCustomIncludes.projectsList,
        filter: {
          is_my_projects: option.value === 'my_projects',
        },
        page: 1,
      })

      this.$emit('handle:filter-select', {
        type: 'projectType',
        value: option.value,
      })
    },
  },
}
</script>
