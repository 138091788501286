<template>
  <div class="vms-projects__list--hours" @mouseover="handleShowProjectHealth">
    <div v-if="!showInlineEditor">
      <div v-if="isLoading">
        <inline-edit-loader :loading-text="loadingText"></inline-edit-loader>
      </div>
      <div v-else>{{ budgetedHours }}</div>
    </div>
    <div v-else>
      <inline-edit-loader v-if="showInlineLoader"></inline-edit-loader>
      <text-inline-editor
        v-else
        input-name="projectsListAllocatedHours"
        input-label="Estimated Hours"
        input-type="number"
        custom-input-class="vd-margin-bottom-20 vd-margin-right-20 vd-margin-left-20 vd-margin-top-extra-small"
        :validation-rules="validationRules"
        :show-label="true"
        :input-value="projectHealth.allocated_hours || 0"
        @handle:inline-save="handleAllocatedHoursUpdate"
      >
        <text-popover
          slot="input-body"
          :tooltip-id="`projectHoursTooltip-${projectId}`"
          custom-tooltip-class="vd-padding-small vd-margin-top-small"
          tooltip-trigger="hover"
          tooltip-placement="right"
          tooltip-text="Hours"
          :show-icon="false"
          :tooltip-label="tooltipLabel"
        >
          <div
            slot="popover-body"
            class="vd-padding-small vms-projects__list--hours-body"
          >
            <div class="vd-h5">
              <strong>Hours</strong>
            </div>

            <div class="row no-gutters">
              <div class="col-8">Estimated hours:</div>
              <div class="vd-text-align-right col-4">
                {{ projectHealth.allocated_hours || 0 }}
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col-8">Scheduled hours:</div>
              <div class="vd-text-align-right col-4">
                {{ projectHealth.total_scheduled_hours }}
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col-8">Worked hours:</div>
              <div class="vd-text-align-right col-4">
                {{ projectHealth.total_hours_worked }}
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col-8">
                <strong>Hourly rate:</strong>
              </div>
              <div class="vd-text-align-right col-4">
                <strong>{{ hourlyRate | moneyFormat }}</strong>
              </div>
            </div>
          </div>
        </text-popover>
      </text-inline-editor>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import FiltersMixin from '@mixins/filters-mixin'
import TextInlineEditor from '@components/inline-edit/text-inline-editor'
import ApiCustomIncludes from '@configs/api-custom-includes'
import InlineEditLoader from '@components/inline-edit/inline-edit-loader'
import { find, isNil } from 'lodash'
import { required, integer } from 'vuelidate/lib/validators'
import { isEmpty } from 'lodash'

export default {
  components: {
    TextPopover,
    TextInlineEditor,
    InlineEditLoader,
  },

  mixins: [FiltersMixin],

  props: {
    budgetedHours: {
      type: Number,
      required: true,
      default: null,
    },
    projectHealth: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    projectId: {
      type: Number,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      loadingText: '',
    }
  },

  computed: {
    ...mapGetters({
      defaultGstValue: 'project/project-health/defaultGstValue',
      selectedProjectId: 'projects/selectedProjectId',
      editProjectLoading: 'project/editProjectLoading',
      selectedFieldsForUpdating: 'projects/selectedFieldsForUpdating',
    }),

    validationRules() {
      return {
        required,
        integer,
      }
    },

    showInlineEditor() {
      return !isEmpty(this.projectHealth)
    },

    showInlineLoader() {
      const isHoursFieldSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.projectId &&
            selectedField.field === 'hours'
          )
        }
      )

      return (
        this.editProjectLoading &&
        this.selectedProjectId === this.projectId &&
        !isNil(isHoursFieldSelected)
      )
    },

    hourlyRate() {
      const totalProjectManagementValue =
        this.projectHealth.total_production_cost / this.defaultGstValue

      const totalProductionCost =
        this.projectHealth.total_production_cost - totalProjectManagementValue

      return this.projectHealth.total_hours_worked > 0
        ? totalProductionCost / this.projectHealth.total_hours_worked
        : 0
    },

    tooltipLabel() {
      if (this.projectHealth.allocated_hours) {
        return `<span>${this.projectHealth.total_hours_worked}</span>/<span>${this.projectHealth.allocated_hours}</span>`
      }

      return '<span class="vd-icon-pencil-circle vms-projects__list--actions-icon"></span>'
    },
  },

  methods: {
    ...mapActions({
      inLineUpdateProjectDetails: 'projects/inLineUpdateProjectDetails',
      getProjectHealth: 'projects/getProjectHealth',
    }),

    handleAllocatedHoursUpdate(allocatedHour) {
      this.inLineUpdateProjectDetails({
        projectId: this.projectId,
        includes: ApiCustomIncludes.projectsList,
        selectedField: { project_id: this.projectId, field: 'hours' },
        payload: {
          budgeted_hours: allocatedHour,
        },
      })
    },

    handleShowProjectHealth() {
      if (isEmpty(this.projectHealth) && !this.isLoading) {
        // Added this condition (!this.isLoading) to avoid multiple API calls.
        this.isLoading = true
        this.getProjectHealth(this.projectId)
      }
    },
  },
}
</script>
