<template>
  <inline-edit-loader v-if="showInlineLoader"></inline-edit-loader>

  <user-inline-editor
    v-else
    :dropdown-select-list="producers"
    :dropdown-select-config="dropdownConfig"
    custom-input-div-class="vms-projects__list--users__edit vd-margin-small"
    input-placeholder="Select Collaborators"
    @handle:inline-user-save="handleUserUpdate"
  >
    <div slot="inline-body-header" class="vd-margin-small">
      <div
        v-if="hasSelectedUser"
        class="vd-background-lightest-grey vd-border-radius-100 vd-position-relative vd-padding-small"
      >
        <user-profile
          :user="selectedUser"
          class="vd-margin-right-small"
          custom-image-class="vms-projects__list--users-profile"
          custom-avatar-class="vms-projects__list--users-avatar"
          :custom-avatar-font-size="45"
        ></user-profile>

        <span>{{ selectedUser.full_name }}</span>

        <span
          class="vd-position-absolute vms-projects__list--users__edit--remove"
          @click="handleRemoveUser"
        >
          <span
            class="vd-icon-close-circle vms-projects__list--users__edit--remove-icon"
          ></span>
        </span>
      </div>

      <div
        :class="[
          'vd-padding-top-small',
          hasSelectedUser
            ? 'vd-margin-top-medium vd-border-lighter-grey vms-projects__list--users__edit--divider'
            : '',
        ]"
      >
        <div class="row no-gutters">
          <div
            :class="[
              'vd-margin-right-medium vd-padding-bottom-extra-small',
              isProductionCoordinatorSelected
                ? 'vd-border-blue vms-projects__list--users__edit--selected'
                : 'vd-blue vms-projects__list--users__edit--unselected',
            ]"
            @click="selectedCollaboratorType = 'production-coordinator'"
          >
            Production Coordinator
          </div>
          <div
            :class="[
              'vd-padding-bottom-extra-small',
              isDirectorSelected
                ? 'vd-border-blue vms-projects__list--users__edit--selected'
                : 'vd-blue vms-projects__list--users__edit--unselected',
            ]"
            @click="selectedCollaboratorType = 'director'"
          >
            Director
          </div>
        </div>
      </div>
    </div>

    <div slot="inline-body" class="vd-position-relative">
      <div v-if="isDirector || isProductionCoordinator">
        <staff-popover
          v-if="isProductionCoordinator"
          :selected-user="productionCoordinator"
          :tooltip-id="`product-coordinator-${productionCoordinator.id}-project-${projectId}`"
          class="vd-padding-extra-small vd-display-inline-block"
          :label-avatar-font-size="45"
          label-avatar-class="vms-projects__list--users-avatar"
          label-avatar-image-class="vms-projects__list--users-profile"
          popover-avatar-image-class="vms-projects__list--users-profile__info"
        ></staff-popover>

        <staff-popover
          v-if="isDirector"
          :selected-user="director"
          :tooltip-id="`director-${director.id}-project-${projectId}`"
          class="vd-padding-extra-small vd-display-inline-block"
          :label-avatar-font-size="45"
          label-avatar-class="vms-projects__list--users-avatar"
          label-avatar-image-class="vms-projects__list--users-profile"
          popover-avatar-image-class="vms-projects__list--users-profile__info"
        ></staff-popover>
      </div>

      <div v-else>
        <text-popover
          class="vd-display-inline-block"
          tooltip-text="Add Collaborators"
          tooltip-placement="right"
          tooltip-icon="vd-icon-plus-circle"
          :tooltip-id="`projectAddCollaboratorTooltip-${projectId}`"
        ></text-popover>
      </div>
    </div>
  </user-inline-editor>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, find, isNil } from 'lodash'
import StaffPopover from '@components/staff-popover/staff-popover'
import UserInlineEditor from '@components/inline-edit/user-inline-editor'
import UserProfile from '@components/user-profile/user-profile'
import ApiCustomIncludes from '@configs/api-custom-includes'
import TextPopover from '@components/tooltips/text-popover'
import InlineEditLoader from '@components/inline-edit/inline-edit-loader'

export default {
  components: {
    StaffPopover,
    UserInlineEditor,
    UserProfile,
    TextPopover,
    InlineEditLoader,
  },

  props: {
    projectId: {
      type: Number,
      required: true,
      default: null,
    },

    director: {
      type: [Object, Array],
      required: false,
      default: () => {
        return {}
      },
    },

    productionCoordinator: {
      type: [Object, Array],
      required: false,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      selectedCollaboratorType: 'production-coordinator',
    }
  },

  computed: {
    ...mapGetters({
      producers: 'user/producers',
      selectedProjectId: 'projects/selectedProjectId',
      editProjectLoading: 'project/editProjectLoading',
      selectedFieldsForUpdating: 'projects/selectedFieldsForUpdating',
    }),

    showInlineLoader() {
      const isUserFieldSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.projectId &&
            selectedField.field === 'collaborators'
          )
        }
      )

      return (
        this.editProjectLoading &&
        this.selectedProjectId === this.projectId &&
        !isNil(isUserFieldSelected)
      )
    },

    dropdownConfig() {
      return {
        option_value: 'id',
        option_text: 'full_name',
      }
    },

    selectedUser() {
      if (this.isProductionCoordinatorSelected) {
        return this.isProductionCoordinator ? this.productionCoordinator : null
      } else {
        return this.isDirector ? this.director : null
      }
    },

    hasSelectedUser() {
      return !isEmpty(this.selectedUser)
    },

    isDirector() {
      return !isEmpty(this.director)
    },

    isProductionCoordinator() {
      return !isEmpty(this.productionCoordinator)
    },

    isProductionCoordinatorSelected() {
      return this.selectedCollaboratorType === 'production-coordinator'
    },

    isDirectorSelected() {
      return this.selectedCollaboratorType === 'director'
    },
  },

  methods: {
    ...mapActions({
      inLineUpdateProjectDetails: 'projects/inLineUpdateProjectDetails',
    }),

    handleUserUpdate(user) {
      const payload = this.isProductionCoordinatorSelected
        ? { production_coordinator_id: user.id }
        : { director_id: user.id }

      this.inLineUpdateProjectDetails({
        projectId: this.projectId,
        includes: ApiCustomIncludes.projectsList,
        selectedField: { project_id: this.projectId, field: 'collaborators' },
        payload: payload,
      })
    },

    handleRemoveUser() {
      const payload = this.isProductionCoordinatorSelected
        ? { production_coordinator_id: null }
        : { director_id: null }

      this.inLineUpdateProjectDetails({
        projectId: this.projectId,
        includes: ApiCustomIncludes.projectsList,
        selectedField: { project_id: this.projectId, field: 'collaborators' },
        payload: payload,
      })
    },
  },
}
</script>
