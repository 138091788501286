var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeModalOutside),expression:"closeModalOutside"}],class:[
      'vms-filters__dropdown vd-position-relative vd-display-block',
      _vm.hideSelectedText ? 'vms-filters__dropdown--selected-text-hidden' : '' ]},[_c('div',{class:[
        'vms-filters__dropdown--container vd-padding-small vd-border-radius-2 vd-border-width-1',
        _vm.customActiveClass || 'vd-background-white vd-border-grey' ],on:{"click":_vm.handleToggleDropdownList}},[_vm._t("filter-icon",[(_vm.selectedIcon)?_c('span',{class:[
            'vms-filters__dropdown__icons vd-margin-right-small vms-filters__align-middle',
            _vm.selectedIcon ]}):_vm._e()]),(!_vm.hideSelectedText)?_c('span',{staticClass:"vd-margin-right-small vms-filters__align-middle vms-filters__dropdown__label"},[_vm._v(_vm._s(_vm.selectedText))]):_vm._e(),_c('span',{class:[
          'vms-filters__dropdown__icons--arrow vms-filters__align-middle',
          _vm.customActiveClass
            ? 'vd-icon-arrow-down-white'
            : 'vd-icon-arrow-down-grey-black' ]})],2),(_vm.showDropdownList && _vm.displayDropdownList)?_c('div',{class:[
        'vms-filters__dropdown--list vd-position-absolute vd-background-white vd-border-grey vd-margin-top-extra-small vd-border-width-1',
        _vm.customDropdownListClass ]},[_c('div',{staticClass:"vms-filters__dropdown--list-options__wrapper"},[_vm._t("filter-dropdown-header"),_vm._t("filter-dropdown-list",_vm._l((_vm.dropdownOptions),function(option,index){return _c('div',{key:index,staticClass:"vms-filters__dropdown--list-options vd-padding-small",on:{"click":function($event){return _vm.handleOptionSelect(option)}}},[_c('span',[_vm._v(_vm._s(option.text))])])}))],2),(_vm.hasActionButtons)?_c('div',{staticClass:"vms-filters__dropdown--list-options__footer vd-text-align-right vd-padding-extra-small vd-border-grey"},[(_vm.showClearOption)?_c('span',{staticClass:"vms-filters__dropdown--list-options__footer--clear vd-red vd-margin-right-small",on:{"click":_vm.handleClear}},[_vm._v("Clear")]):_vm._e(),_c('button',{staticClass:"vd-btn-small vd-btn-grey vd-margin-extra-small",on:{"click":_vm.handleCancelClick}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"vd-btn-small vd-btn-blue vd-margin-extra-small",on:{"click":_vm.handleApplyClick}},[_vm._v(" Apply ")])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }