<template>
  <filters-dropdown
    class="vd-margin-right-medium vd-padding-bottom-extra-small vd-display-inline-block"
    :custom-active-class="dropdownActiveClass"
    :custom-dropdown-list-class="dropdownListClass"
    :hide-selected-text="hideSelectedText"
    :selected-text="selectedText"
    :has-action-buttons="true"
    :show-clear-option="true"
    @handle:apply-click="handleProjectDateUpdate"
    @handle:cancel-click="handleCancelClick"
    @handle:clear-click="handleClearClick"
  >
    <i
      slot="filter-icon"
      :class="[iconColor, 'vd-icon icon-calendar-days vd-margin-right-small']"
    ></i>
    <div
      slot="filter-dropdown-list"
      class="vd-display-flex vms-projects__datepicker"
    >
      <div class="vms-projects__datepicker--start">
        <div
          class="vd-padding-top-small vd-padding-right-small vd-padding-left-medium vd-background-aqua vd-black"
        >
          Created date from
        </div>
        <v-date-picker
          v-model="startDate"
          :show-current="startDate"
          color="#00c5ff"
          header-color="#dbf3fc"
          :width="250"
        ></v-date-picker>
      </div>

      <div class="vms-projects__datepicker--end">
        <div
          class="vd-padding-top-small vd-padding-right-small vd-padding-left-medium vd-background-aqua vd-black"
        >
          Created date to
        </div>
        <v-date-picker
          v-model="endDate"
          :show-current="endDate"
          color="#00c5ff"
          header-color="#dbf3fc"
          :width="250"
        ></v-date-picker>
      </div>
    </div>
  </filters-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersDropdown from '@components/filters/filter-dropdown'
import momentTz from 'moment-timezone'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    FiltersDropdown,
  },

  props: {
    hideSelectedText: {
      type: Boolean,
      required: false,
      default: false,
    },

    customActiveClass: {
      type: String,
      required: false,
      default: '',
    },

    customDropdownListClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      startDate: null,
      endDate: null,
    }
  },

  computed: {
    ...mapGetters({
      selectedFromDate: 'projects/selectedFromDate',
      selectedToDate: 'projects/selectedToDate',
    }),

    selectedText() {
      if (this.selectedFromDate && this.selectedToDate) {
        return `${momentTz(this.selectedFromDate).format(
          'DD/MM/YYYY'
        )} - ${momentTz(this.selectedToDate).format('DD/MM/YYYY')}`
      }

      return 'Select created date'
    },

    iconColor() {
      return this.customActiveClass &&
        (this.selectedFromDate || this.selectedToDate)
        ? 'vd-white'
        : 'vd-black'
    },

    dropdownActiveClass() {
      return this.selectedFromDate || this.selectedToDate
        ? this.customActiveClass
        : ''
    },

    dropdownListClass() {
      return `vms-projects__filters--created ${this.customDropdownListClass}`
    },
  },

  mounted() {
    this.startDate = this.selectedFromDate
      ? this.selectedFromDate
      : momentTz().format('YYYY-MM-DD')
    this.endDate = this.selectedToDate
      ? this.selectedToDate
      : momentTz().format('YYYY-MM-DD')
  },

  methods: {
    ...mapActions({
      getAllProjects: 'projects/getAllProjects',
      updateProjectFromDate: 'projects/updateProjectFromDate',
      updateProjectToDate: 'projects/updateProjectToDate',
      setProjectsFilterCookies: 'projects/setProjectsFilterCookies',
    }),

    handleCancelClick() {
      this.startDate = this.selectedFromDate
        ? momentTz(this.selectedFromDate).format('YYYY-MM-DD')
        : momentTz().format('YYYY-MM-DD')
      this.endDate = this.selectedToDate
        ? momentTz(this.selectedToDate).format('YYYY-MM-DD')
        : momentTz().format('YYYY-MM-DD')
    },

    handleClearClick() {
      this.startDate = momentTz().format('YYYY-MM-DD')
      this.endDate = momentTz().format('YYYY-MM-DD')

      this.updateProjectFromDate(null)
      this.updateProjectToDate(null)

      this.setProjectsFilterCookies({
        type: 'createdDate',
        value: null,
      })

      this.getAllProjects({
        include: ApiCustomIncludes.projectsList,
        filter: {
          from: null,
          to: null,
        },
        page: 1,
      })
    },

    handleProjectDateUpdate() {
      const fromDate = momentTz(this.startDate).format('YYYY-MM-DD')
      const toDate = momentTz(this.endDate).format('YYYY-MM-DD')

      this.updateProjectFromDate(fromDate)
      this.updateProjectToDate(toDate)

      this.getAllProjects({
        include: ApiCustomIncludes.projectsList,
        filter: {
          from: fromDate,
          to: toDate,
        },
        page: 1,
      })

      this.$emit('handle:filter-select', {
        type: 'createdDate',
        value: {
          start: fromDate,
          end: toDate,
        },
      })
    },
  },
}
</script>
