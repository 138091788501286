<template>
  <div class="vms-projects__list--due-date">
    <inline-edit-loader v-if="showInlineLoader"></inline-edit-loader>

    <date-duration-inline-editor
      v-else
      :default-start-date="defaultStartDate"
      :default-end-date="defaultEndDate"
      @handle:inline-date-save="handleProjectDatesUpdate"
    >
      <text-popover
        slot="inline-body"
        :tooltip-id="tooltipId"
        custom-tooltip-class="vd-padding-small vd-margin-top-small"
        tooltip-trigger="hover"
        tooltip-placement="right"
        :tooltip-text="tooltipText"
        :show-icon="false"
        :tooltip-label="tooltipLabel"
      >
        <div
          v-if="defaultDate"
          slot="popover-body"
          class="vd-padding-small vms-projects__list--due-date-body"
        >
          <div class="vd-h5">
            <strong>{{ defaultDate | unixHumanDiff }}</strong>
          </div>
        </div>
      </text-popover>
    </date-duration-inline-editor>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import FiltersMixin from '@mixins/filters-mixin'
import DateDurationInlineEditor from '@components/inline-edit/date-duration-inline-editor'
import ApiCustomIncludes from '@configs/api-custom-includes'
import momentTz from 'moment-timezone'
import InlineEditLoader from '@components/inline-edit/inline-edit-loader'
import { isNil, find } from 'lodash'

export default {
  components: {
    TextPopover,
    DateDurationInlineEditor,
    InlineEditLoader,
  },

  mixins: [FiltersMixin],

  props: {
    projectId: {
      type: Number,
      required: true,
      default: null,
    },

    tooltipId: {
      type: String,
      required: true,
      default: '',
    },

    tooltipText: {
      type: String,
      required: true,
      default: 'Dates',
    },

    defaultDate: {
      type: Number,
      required: false,
      default: null,
    },

    defaultStartDate: {
      type: Number,
      required: false,
      default: null,
    },

    defaultEndDate: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      selectedProjectId: 'projects/selectedProjectId',
      editProjectLoading: 'project/editProjectLoading',
      selectedFieldsForUpdating: 'projects/selectedFieldsForUpdating',
    }),

    showInlineLoader() {
      const isDatesFieldSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.projectId &&
            selectedField.field === 'dates'
          )
        }
      )

      return (
        this.editProjectLoading &&
        this.selectedProjectId === this.projectId &&
        !isNil(isDatesFieldSelected)
      )
    },

    tooltipLabel() {
      if (this.defaultDate) {
        return `<span>${this.$options.filters.unixToFormat(
          this.defaultDate,
          'DD/MM/YY'
        )}</span>`
      }

      return '<span class="vd-icon-pencil-circle vms-projects__list--actions-icon"></span>'
    },
  },

  methods: {
    ...mapActions({
      inLineUpdateProjectDetails: 'projects/inLineUpdateProjectDetails',
    }),

    handleProjectDatesUpdate(selectedDates) {
      this.inLineUpdateProjectDetails({
        projectId: this.projectId,
        includes: ApiCustomIncludes.projectsList,
        selectedField: { project_id: this.projectId, field: 'dates' },
        payload: {
          start_date: momentTz(selectedDates.start_date).unix(),
          due_date: momentTz(selectedDates.end_date).unix(),
        },
      })
    },
  },
}
</script>
