<template>
  <div>
    <v-edit-dialog
      :return-value.sync="textValue"
      @save="handleSave"
      @cancel="handleCancel"
      @close="handleClose"
    >
      <slot name="input-body">
        {{ inputValue }}
      </slot>
      <template v-slot:input :class="customInputDivClass">
        <textarea
          v-if="isInputTypeTextarea"
          v-model="textValue"
          :class="[
            customInputClass,
            isFieldInvalid ? 'vd-border-red' : 'vd-border-dark-grey',
          ]"
        ></textarea>

        <input-component
          v-else
          :type="inputType"
          :name="inputName"
          :label="inputLabel"
          :class="customInputClass"
          :required="required"
          :value="inputValue"
          :show-label="showLabel"
          :container-custom-classes="[
            setInputValidationClass('textValue', false),
          ]"
          @formInput="handleInputUpdate($event, 'textValue')"
        ></input-component>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import InputComponent from '@components/form/form-input'
import FormMixin from '@mixins/forms-mixin'

export default {
  components: {
    InputComponent,
  },

  mixins: [FormMixin],

  props: {
    inputValue: {
      type: [String, Number],
      required: false,
      default: '',
    },

    inputName: {
      type: String,
      required: true,
      default: '',
    },

    inputLabel: {
      type: String,
      required: true,
      default: '',
    },

    inputType: {
      type: String,
      required: false,
      default: 'text',
    },

    customInputDivClass: {
      type: String,
      required: false,
      default: 'vd-padding-20',
    },

    customInputClass: {
      type: String,
      required: false,
      default: 'vd-padding-20',
    },

    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    required: {
      type: Boolean,
      required: false,
      default: true,
    },

    validationRules: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },

  validations() {
    return { textValue: this.validationRules }
  },

  data() {
    return {
      textValue: '',
      maxCharacterLength: 255,
    }
  },

  computed: {
    isInputTypeText() {
      return this.inputType === 'text'
    },

    isInputTypeTextarea() {
      return this.inputType === 'textarea'
    },

    isFieldInvalid() {
      return this.$v.$invalid
    },
  },

  mounted() {
    this.textValue = this.inputValue
  },

  methods: {
    handleSave() {
      this.$emit('handle:inline-save', this.textValue)
    },

    handleCancel() {
      this.$emit('handle:inline-cancel', this.textValue)
    },

    handleClose() {
      this.$emit('handle:inline-close', this.textValue)
    },
  },
}
</script>
