<template>
  <div>
    <inline-edit-loader
      v-if="showLoader"
      loading-text="Loading..."
    ></inline-edit-loader>
    <project-status v-else :project-status="projectStatus"></project-status>
  </div>
</template>

<script>
import ProjectStatus from '@components/project-status/project-status'
import InlineEditLoader from '@components/inline-edit/inline-edit-loader'
import { mapGetters } from 'vuex'
import { isNil, find } from 'lodash'

export default {
  components: {
    ProjectStatus,
    InlineEditLoader,
  },

  props: {
    projectStatus: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    projectId: {
      type: Number,
      required: true,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      selectedFieldsForUpdating: 'projects/selectedFieldsForUpdating',
      editProjectLoading: 'project/editProjectLoading',
    }),

    showLoader() {
      const isStatusSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.projectId &&
            selectedField.field === 'status'
          )
        }
      )

      return !isNil(isStatusSelected) && this.editProjectLoading
    },
  },
}
</script>
