<template>
  <div v-if="sideMenu.hasPermission">
    <a
      v-if="isSideMenuExternal"
      class="vms-side-menu__links vd-cursor-pointer vd-border-light-grey vd-border-width-0 vd-border-bottom-width-1"
      :href="sideMenu.route.url"
    >
      <side-menu-label
        :side-menu-icon="sideMenu.icon"
        :has-sub-menu="sideMenu.hasSubMenu"
        :side-menu-label="sideMenu.label"
        :can-show-sub-menu="sideMenu.hasSubMenu"
        :is-route-active="isRouteActive"
      ></side-menu-label>
    </a>

    <router-link
      v-else-if="isSideMenuInternal"
      class="vms-side-menu__links vd-cursor-pointer vd-border-light-grey vd-border-width-0 vd-border-bottom-width-1"
      :to="{ name: sideMenu.route.name }"
    >
      <side-menu-label
        :side-menu-icon="sideMenu.icon"
        :has-sub-menu="sideMenu.hasSubMenu"
        :side-menu-label="sideMenu.label"
        :can-show-sub-menu="sideMenu.hasSubMenu"
        :is-route-active="isRouteActive"
      ></side-menu-label>
    </router-link>

    <div
      v-else
      class="vms-side-menu__links vd-cursor-pointer vd-border-light-grey vd-border-width-0 vd-border-bottom-width-1"
      @click="handleSideMenuClick"
    >
      <side-menu-label
        :side-menu-icon="sideMenu.icon"
        :has-sub-menu="sideMenu.hasSubMenu"
        :side-menu-label="sideMenu.label"
        :can-show-sub-menu="canShowSubMenu"
        :is-route-active="isRouteActive"
      ></side-menu-label>
    </div>

    <side-menu-sub-menu
      v-if="canShowSubMenu"
      :sub-menu-list="sideMenu.subMenuList"
    ></side-menu-sub-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SideMenuLabel from '@layouts/side-menu/components/side-menu-label'
import { isNil } from 'lodash'
import SideMenuSubMenu from '@layouts/side-menu/components/side-menu-sub-menu'

export default {
  components: {
    SideMenuLabel,
    SideMenuSubMenu,
  },

  props: {
    sideMenu: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      isSideMenuOpen: 'common/isSideMenuOpen',
      selectedSideMenu: 'common/selectedSideMenu',
    }),

    sideMenuHasRoute() {
      return !isNil(this.sideMenu.route) && !this.sideMenu.hasSubMenu
    },

    isSideMenuExternal() {
      return this.sideMenuHasRoute && this.sideMenu.route?.isExternal
    },

    isSideMenuInternal() {
      return this.sideMenuHasRoute && !this.sideMenu.route?.isExternal
    },

    isRouteActive() {
      return (
        this.sideMenuHasRoute &&
        this.isSideMenuInternal &&
        this.$route.name === this.sideMenu.route.name
      )
    },

    isSideMenuSelected() {
      return this.selectedSideMenu === this.sideMenu.id || this.isRouteActive
    },

    canShowSubMenu() {
      return (
        this.sideMenu.hasSubMenu &&
        this.isSideMenuOpen &&
        this.isSideMenuSelected
      )
    },
  },

  methods: {
    ...mapActions({
      setSelectedSideMenu: 'common/setSelectedSideMenu',
      toggleSideMenu: 'common/toggleSideMenu',
    }),

    handleSideMenuClick() {
      // Open the side menu if it has sub menu and if the side menu is closed
      if (!this.isSideMenuOpen && this.sideMenu.hasSubMenu) {
        this.toggleSideMenu()
      }

      // Redirect to the selected menu page if the side meu does not have sub menu
      if (!this.sideMenu.hasSubMenu) {
        // Redirect to the selected menu page

        if (this.sideMenu.route.isExternal) {
          window.location = this.sideMenu.route.url
        } else {
          this.$router.push({
            name: this.sideMenu.route.name,
          })
        }

        // Close the side menu if it is open
        if (this.isSideMenuOpen) {
          this.toggleSideMenu()
        }
      }

      this.setSelectedSideMenu(
        this.selectedSideMenu === this.sideMenu.id ? null : this.sideMenu.id
      )
    },
  },
}
</script>
