<template>
  <div>
    <v-edit-dialog
      ref="userInlineDialog"
      @save="handleSave"
      @cancel="handleCancel"
      @close="handleClose"
    >
      <slot name="inline-body"></slot>

      <template v-slot:input>
        <slot name="inline-body-header"></slot>

        <div :class="customInputDivClass">
          <div class="vd-margin-top-medium vd-margin-bottom-medium">
            <input
              v-model="searchText"
              class="vd-border-light-grey vd-padding-small vd-border-radius-6 vms-projects__list--users__search"
              type="text"
              :placeholder="inputPlaceholder"
            />
          </div>

          <div class="vms-projects__list--users__options">
            <div
              v-for="user in defaultDropdownList"
              :key="user.id"
              :class="[
                'vd-padding-small vms-projects__list--users__options--list',
                isUserPreSelected(user) ? 'vd-background-lightest-grey' : '',
              ]"
              @click="handleUserSelect(user)"
            >
              <user-profile
                :user="user"
                class="vd-padding-top-small vd-padding-bottom-small vd-padding-right-small vd-padding-left-extra-small"
                custom-image-class="vms-projects__list--users-profile"
                custom-avatar-class="vms-projects__list--users-avatar"
                :custom-avatar-font-size="45"
              ></user-profile>

              <span>{{ user.full_name }}</span>
            </div>
          </div>
        </div>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import FormMixin from '@mixins/forms-mixin'
import UserProfile from '@components/user-profile/user-profile'
import { filter, includes, toLower } from 'lodash'

export default {
  components: {
    UserProfile,
  },

  mixins: [FormMixin],

  props: {
    customInputDivClass: {
      type: String,
      required: false,
      default: 'vd-padding-20',
    },

    customInputClass: {
      type: String,
      required: false,
      default: 'vd-padding-bottom-20',
    },

    inputLabel: {
      type: String,
      required: false,
      default: 'User',
    },

    inputPlaceholder: {
      type: String,
      required: false,
      default: 'Select a user',
    },

    required: {
      type: Boolean,
      required: false,
      default: true,
    },

    dropdownSelectList: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },

    dropdownSelectConfig: {
      type: Object,
      required: true,
      default: () => {
        return {
          option_value: 'id',
          option_text: '',
        }
      },
    },
  },

  data() {
    return {
      selectedUser: null,
      searchText: '',
      searchResults: null,
    }
  },

  computed: {
    defaultDropdownList() {
      return this.searchResults ? this.searchResults : this.dropdownSelectList
    },
  },

  watch: {
    searchText() {
      if (this.searchText !== '') {
        this.searchResults = filter(this.dropdownSelectList, (user) => {
          return includes(toLower(user.full_name), toLower(this.searchText))
        })

        this.selectedUser = this.searchResults[0]
      } else {
        this.searchResults = this.dropdownSelectList
        this.selectedUser = null
      }
    },
  },

  methods: {
    resetSelection() {
      this.selectedUser = null
      this.searchText = ''
      this.searchResults = null
    },

    isUserPreSelected(user) {
      return this.selectedUser ? this.selectedUser.id === user.id : false
    },

    handleUserSelect(user) {
      this.selectedUser = user

      // Force dialog to close using the save function
      this.$refs.userInlineDialog.save()

      // Manually trigger the handleSave so we can emit the handleSave function back to the parent component
      this.handleSave()
    },

    handleSave() {
      this.$emit('handle:inline-user-save', this.selectedUser)
    },

    handleCancel() {
      this.resetSelection()

      this.$emit('handle:inline-user-cancel', this.selectedUser)
    },

    handleClose() {
      this.resetSelection()

      this.$emit('handle:inline-user-close', this.selectedUser)
    },
  },
}
</script>
