import { find } from 'lodash'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

/**
 * @namespace mixins/permissions-mixins
 */

export default {
  computed: {
    ...mapGetters({
      userPermissions: 'auth/userPermissions',
    }),
  },

  methods: {
    /**
     * Check if user has the permission to access some feature.
     *
     * @param {string} permissionName - takes permission name as param (NOT label)
     *
     * @returns {boolean}
     */
    can(permissionName) {
      return !isEmpty(
        find(this.userPermissions, (userPermission) => {
          return userPermission.name === permissionName
        })
      )
    },
  },
}
