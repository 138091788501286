<template>
  <div>
    <inline-edit-loader v-if="showInlineLoader"></inline-edit-loader>

    <text-inline-editor
      v-else
      input-name="projectsListName"
      input-label="Project Name"
      :input-value="projectName"
      :validation-rules="validationRules"
      @handle:inline-save="handleProjectNameUpdate"
    >
      <div slot="input-body">
        <div v-if="!shouldUsePopover">
          {{ projectName | truncateString(maxNameLength) }}
        </div>

        <text-popover
          v-else
          :tooltip-id="`projectNameTooltip-${projectId}`"
          custom-tooltip-class="vd-padding-small vd-margin-top-small"
          tooltip-trigger="hover"
          tooltip-placement="right"
          :tooltip-text="projectName"
          :show-icon="false"
          :tooltip-label="tooltipLabel"
        >
        </text-popover>
      </div>
    </text-inline-editor>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormMixin from '@mixins/forms-mixin'
import FiltersMixin from '@mixins/filters-mixin'
import { required, maxLength } from 'vuelidate/lib/validators'
import TextInlineEditor from '@components/inline-edit/text-inline-editor'
import ApiCustomIncludes from '@configs/api-custom-includes'
import InlineEditLoader from '@components/inline-edit/inline-edit-loader'
import { find, isNil, size } from 'lodash'
import TextPopover from '@components/tooltips/text-popover'

export default {
  components: {
    TextInlineEditor,
    InlineEditLoader,
    TextPopover,
  },

  mixins: [FormMixin, FiltersMixin],

  props: {
    projectName: {
      type: String,
      required: false,
      default: '',
    },

    projectId: {
      type: Number,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      maxNameLength: 60,
    }
  },

  validations: {
    name: {
      required,
    },
  },

  computed: {
    ...mapGetters({
      selectedProjectId: 'projects/selectedProjectId',
      editProjectLoading: 'project/editProjectLoading',
      selectedFieldsForUpdating: 'projects/selectedFieldsForUpdating',
    }),

    validationRules() {
      return {
        required,
        maxLength: maxLength(255),
      }
    },

    shouldUsePopover() {
      return size(this.projectName) > this.maxNameLength
    },

    tooltipLabel() {
      return `<div>${this.$options.filters.truncateString(
        this.projectName,
        this.maxNameLength
      )}</div>`
    },

    showInlineLoader() {
      const isNameFieldSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.projectId &&
            selectedField.field === 'name'
          )
        }
      )

      return (
        this.editProjectLoading &&
        this.selectedProjectId === this.projectId &&
        !isNil(isNameFieldSelected)
      )
    },
  },

  methods: {
    ...mapActions({
      inLineUpdateProjectDetails: 'projects/inLineUpdateProjectDetails',
    }),

    handleProjectNameUpdate(projectName) {
      this.inLineUpdateProjectDetails({
        projectId: this.projectId,
        includes: ApiCustomIncludes.projectsList,
        selectedField: { project_id: this.projectId, field: 'name' },
        payload: {
          name: projectName,
        },
      })
    },
  },
}
</script>
