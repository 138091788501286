<template>
  <div
    id="holdProjectDiv"
    :class="customParentDivClass"
    @click="handleAction()"
  >
    <slot name="body"></slot>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SweetAlert from '@plugins/sweet-alert'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  props: {
    project: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    customParentDivClass: {
      type: String,
      required: false,
      default: 'vd-display-inline-block',
    },

    updateProjectList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    ...mapActions({
      holdProject: 'project/holdProject',
    }),

    handleAction() {
      const alertContent = `
        <div class="vd-margin-top-small">Are you sure you want to hold </div>
        <div class="vd-margin-top-small vd-margin-bottom-small"><strong>${this.project.name}</strong>?</div>
        <div class="vd-margin-top-large">Putting this project on hold will hold all jobs and events.</div>
      `

      SweetAlert.fire({
        title: 'Project hold',
        html: alertContent,
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        showConfirmButton: true,
        confirmButtonText: 'Hold',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.holdProject({
            projectId: this.project.id,
            name: this.project.name,
            updateProjectList: this.updateProjectList,
            selectedFields: [
              {
                project_id: this.project.id,
                field: 'status',
              },
              {
                project_id: this.project.id,
                field: 'jobs',
              },
            ],
            includes: this.updateProjectList
              ? ApiCustomIncludes.projectsList
              : [],
          })
        }
      })
    },
  },
}
</script>
