var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-projects__filters"},[_c('project-type-filter',{class:{ 'col-12 vd-margin-bottom-small': _vm.isMobileFilter },attrs:{"hide-selected-text":_vm.hideSelectedText,"custom-active-class":_vm.customActiveClass},on:{"handle:filter-select":_vm.setProjectsFilterCookies}}),_c('project-created-filter',{class:{ 'col-12 vd-margin-bottom-small': _vm.isMobileFilter },attrs:{"hide-selected-text":_vm.hideSelectedText,"custom-active-class":_vm.customActiveClass,"custom-dropdown-list-class":_vm.isMobileFilter
        ? 'vms-filters__dropdown--fullscreen vd-margin-top-0'
        : ''},on:{"handle:filter-select":_vm.setProjectsFilterCookies}}),_c('project-user-filter',{class:{ 'col-12 vd-margin-bottom-small': _vm.isMobileFilter },attrs:{"hide-selected-text":_vm.hideSelectedText,"custom-active-class":_vm.customActiveClass,"custom-dropdown-list-class":_vm.isMobileFilter
        ? 'vms-filters__dropdown--fullscreen vd-margin-top-0'
        : ''},on:{"handle:filter-select":_vm.setProjectsFilterCookies}}),_c('project-status-filter',{class:{ 'col-12 vd-margin-bottom-small': _vm.isMobileFilter },attrs:{"hide-selected-text":_vm.hideSelectedText,"custom-active-class":_vm.customActiveClass,"custom-dropdown-list-class":_vm.isMobileFilter
        ? 'vms-filters__dropdown--fullscreen vd-margin-top-0'
        : ''},on:{"handle:filter-select":_vm.setProjectsFilterCookies}}),_c('project-product-type-filter',{class:{ 'col-12 vd-margin-bottom-small': _vm.isMobileFilter },attrs:{"hide-selected-text":_vm.hideSelectedText,"custom-active-class":_vm.customActiveClass,"custom-dropdown-list-class":_vm.isMobileFilter
        ? 'vms-filters__dropdown--fullscreen vd-margin-top-0'
        : ''},on:{"handle:filter-select":_vm.setProjectsFilterCookies}}),_c('div',{staticClass:"vd-padding-small vms-filters__dropdown--list-options__clear vd-display-inline-block vd-margin-bottom-extra-small",attrs:{"id":"clearProjectsFilterDiv"},on:{"click":_vm.handleFilterClear}},[_c('span',{staticClass:"vd-light-blue"},[_vm._v("Clear")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }