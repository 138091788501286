<template>
  <filters-dropdown
    class="vd-margin-right-medium vd-padding-bottom-extra-small vd-display-inline-block"
    :custom-active-class="dropdownActiveClass"
    :custom-dropdown-list-class="customDropdownListClass"
    :hide-selected-text="hideSelectedText"
    :selected-text="selectedProductTypeName"
    :has-action-buttons="true"
    @handle:apply-click="handleProductTypeUpdate"
    @handle:cancel-click="handleCancelClick"
  >
    <i
      slot="filter-icon"
      :class="[
        iconColor,
        'vd-icon vd-margin-right-small',
        selectedProductTypeFilter.icon,
      ]"
    ></i>
    <div slot="filter-dropdown-list">
      <div class="vd-padding-small vms-filters__dropdown--list-options">
        <label for="allProductTypesCheckbox">
          <input
            id="allProductTypesCheckbox"
            v-model="isAllProductTypesSelected"
            type="checkbox"
            class="vd-margin-right-small"
            value="all"
            :checked="selectedProductTypeFilter.text === 'All Product Types'"
            @change="handleAllProductTypesSelect"
          />

          <i
            class="vd-icon icon-tags vd-align-middle vd-h3 vd-margin-right-small"
          ></i>
          <span>All Product Types</span>
        </label>
      </div>

      <div
        v-for="(productType, index) in dropdownOptions"
        :key="index"
        class="vd-padding-small vms-filters__dropdown--list-options text-nowrap"
      >
        <input
          :id="`productTypeFilterCheckbox-${index}`"
          v-model="selectedProductTypes"
          type="checkbox"
          class="vd-margin-right-small"
          :value="productType.id"
        />

        <label :for="`productTypeFilterCheckbox-${index}`">
          <i
            :class="[
              'vd-icon vd-align-middle vd-h3 vd-margin-right-small',
              productType.icon,
            ]"
          ></i>
          <span>{{ productType.text }}</span>
        </label>
      </div>
    </div>
  </filters-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersDropdown from '@components/filters/filter-dropdown'
import FiltersMixin from '@mixins/filters-mixin'
import ProductTypesConfig from '@configs/product-types'
import ApiCustomIncludes from '@configs/api-custom-includes'
import { forEach, map, sortBy, size } from 'lodash'

export default {
  components: {
    FiltersDropdown,
  },

  mixins: [FiltersMixin],

  props: {
    hideSelectedText: {
      type: Boolean,
      required: false,
      default: false,
    },
    customActiveClass: {
      type: String,
      required: false,
      default: '',
    },
    customDropdownListClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      selectedProductTypes: [],
      isAllProductTypesSelected: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedProductTypeFilter: 'projects/selectedProductTypeFilter',
      projectsFilter: 'projects/projectsFilter',
    }),

    selectedProductTypeName() {
      return `${this.$options.filters.truncateString(
        this.selectedProductTypeFilter.text,
        18
      )}`
    },

    dropdownOptions() {
      return sortBy(ProductTypesConfig.productTypes, 'text')
    },

    iconColor() {
      return this.customActiveClass &&
        size(this.projectsFilter.product_types) > 0
        ? 'vd-white'
        : 'vd-black'
    },

    dropdownActiveClass() {
      return size(this.projectsFilter.product_types) > 0
        ? this.customActiveClass
        : ''
    },
  },

  mounted() {
    forEach(
      this.selectedProductTypeFilter.selectedProductTypes,
      (selectedProductType) => {
        this.selectedProductTypes.push(selectedProductType)
      }
    )

    this.isAllProductTypesSelected =
      this.selectedProductTypeFilter.text === 'All Product Types'
  },

  methods: {
    ...mapActions({
      getAllProjects: 'projects/getAllProjects',
      updateProjectProductTypeFilter: 'projects/updateProjectProductTypeFilter',
    }),

    handleAllProductTypesSelect() {
      if (!this.isAllProductTypesSelected) {
        this.selectedProductTypes = []
      } else {
        this.selectedProductTypes = map(ProductTypesConfig.productTypes, 'id')
      }
    },

    handleCancelClick() {
      this.selectedProductTypes = []

      forEach(
        this.selectedProductTypeFilter.selectedProductTypes,
        (productType) => {
          this.selectedProductTypes.push(productType)
        }
      )
    },

    handleProductTypeUpdate() {
      this.updateProjectProductTypeFilter(this.selectedProductTypes)

      this.getAllProjects({
        include: ApiCustomIncludes.projectsList,
        filter: {
          productTypes: this.selectedProductTypes.join(','),
        },
        page: 1,
      })

      this.$emit('handle:filter-select', {
        type: 'productTypes',
        value: this.selectedProductTypes,
      })
    },
  },
}
</script>
