<template>
  <div
    :class="[
      'vms-side-menu__link vd-padding-medium vd-border-width-0 vd-border-left-width-5',
      isRouteActive ? 'vd-border-blue' : 'vd-border-transparent',
    ]"
  >
    <div>
      <i :class="['vd-icon vd-blue vd-hover-black', sideMenuIcon]"></i>
    </div>

    <div class="vms-side-menu__link--info vd-h4 col-12 no-gutters">
      <div :class="['vd-blue', hasSubMenu ? 'col-10' : 'col-11']">
        {{ sideMenuLabel }}
      </div>
      <div v-if="hasSubMenu" class="col-1">
        <i
          :class="[
            'vd-icon vd-dark-grey vd-hover-black',
            canShowSubMenu ? 'icon-chevron-up' : 'icon-chevron-down ',
          ]"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sideMenuIcon: {
      type: String,
      required: true,
    },

    hasSubMenu: {
      type: Boolean,
      required: true,
    },

    sideMenuLabel: {
      type: String,
      required: true,
    },

    canShowSubMenu: {
      type: Boolean,
      required: true,
    },

    isRouteActive: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
