<template>
  <v-app>
    <div
      class="container-fluid vd-background-aqua vd-padding-small vd-position-relative"
    >
      <div
        class="vms-projects__new vd-position-relative"
        @click="handleAddNewProject"
      >
        <div
          id="openNewProjectFormDiv"
          :class="[
            'vd-icon-plus-round-blue vms-projects__new--icon vd-position-absolute',
            isSmallScreen ? 'vms-projects__new--icon--mobile' : '',
          ]"
        ></div>
      </div>

      <div class="row no-gutters vms-projects__controls-wrapper">
        <div
          v-if="!projectsListLoading"
          class="col-auto vms-projects__controls"
        >
          <div :class="['row no-gutters', controlsClass]">
            <search-component
              :class="searchClass"
              :search-text="search"
              @input:search="search = $event"
              @enter:search="searchAndSortProjects()"
            ></search-component>

            <button
              v-if="isSmallScreen"
              id="toggleFilterButton"
              :class="[
                'vms-projects__btn-filter vd-border-width-1 vd-padding-small',
                hasSelectedFilter
                  ? 'vd-background-light-blue vd-border-light-blue '
                  : 'vd-background-white vd-border-grey',
              ]"
              @click="showFilters = !showFilters"
            >
              <span
                :class="['vms-projects__btn-filter--icon', filterIconClass]"
              ></span>
            </button>

            <projects-list-filter
              v-if="!isSmallScreen || showFilters"
              :class="projectsListFilterClass"
              :custom-active-class="
                isMediumScreen || isSmallScreen
                  ? 'vms-filters__dropdown--active vd-background-light-blue vd-border-light-blue vd-white'
                  : ''
              "
              :hide-selected-text="isMediumScreen && !isSmallScreen"
              :is-mobile-filter="isSmallScreen"
            ></projects-list-filter>
          </div>
        </div>

        <hr
          v-if="!projectsListLoading && showHorizontalSeparator"
          class="col-12 vd-border-grey vd-margin-top-small vd-margin-bottom-medium"
        />

        <div
          :class="[
            'vms-projects__pagination col-auto vd-margin-right-80',
            !projectsListLoading ? 'vd-margin-bottom-extra-small' : '',
            isSmallScreen ? 'vms-projects__pagination--center' : '',
          ]"
        >
          <div class="vms-pagination">
            <pagination-component
              :total-rows="projectsList.meta.total"
              :page="currentPage"
              :per-page="selectedPerPage"
              :last-page="projectsList.meta.last_page"
              :show-page-in-route="pagination.showPageInRoute"
              :limit-options="pagination.limitOptions"
              @update:page="currentPageUpdate"
              @update:per_page="projectsListPerPageUpdate"
            ></pagination-component>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="[
        'vms-projects__table--container',
        isMobileScreen ? 'vms-projects__table--container__mobile' : '',
      ]"
    >
      <v-data-table
        ref="projectsListTable"
        :class="[
          'vms-projects__table vd-padding-medium',
          !hasProjects ? 'vms-projects__table--empty' : '',
        ]"
        :headers="projectHeaders"
        :items="projectsList.data"
        :options.sync="options"
        :server-items-length="projectsList.meta.total"
        :loading="projectsListLoading"
        :fixed-header="!isMobileScreen"
        :mobile-breakpoint="tableBreakpoint"
        loading-text="Loading Projects..."
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template slot="no-data">
          <projects-list-no-results></projects-list-no-results>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <router-link
            class="vd-a"
            :to="{
              name: 'project.details.summary',
              params: { projectId: item.id },
            }"
            >#{{ item.id }}</router-link
          >
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <projects-list-name
            :project-name="item.name"
            :project-id="item.id"
          ></projects-list-name>
        </template>

        <template v-slot:[`item.product_type.name`]="{ item }">
          <projects-list-product-type
            v-if="item.product_type"
            :product-type="item.product_type"
            :project-id="item.id"
          ></projects-list-product-type>
        </template>

        <template v-slot:[`item.office.name`]="{ item }">
          <projects-list-office
            v-if="item.office"
            :office-name="item.office.name"
            :project-id="item.id"
          ></projects-list-office>
        </template>

        <template v-slot:[`item.account_manager.full_name`]="{ item }">
          <projects-list-staff-display
            type="relationship_manager"
            :project-id="item.id"
            :relationship-manager="item.account_manager"
          ></projects-list-staff-display>
        </template>

        <template v-slot:[`item.producer.full_name`]="{ item }">
          <projects-list-staff-display
            type="project_manager"
            :project-id="item.id"
            :project-manager="item.producer"
          ></projects-list-staff-display>
        </template>

        <template v-slot:[`item.collaborators`]="{ item }">
          <projects-list-collaborators
            :project-id="item.id"
            :director="item.director"
            :production-coordinator="item.production_coordinator"
          ></projects-list-collaborators>
        </template>

        <template v-slot:[`item.project_status.name`]="{ item }">
          <projects-list-status
            v-if="item.project_status_id"
            :project-status="item.project_status"
            :project-id="item.id"
          ></projects-list-status>
        </template>

        <template v-slot:[`item.notes`]="{ item }">
          <projects-list-notes
            :notes="item.notes"
            :project-id="item.id"
          ></projects-list-notes>
        </template>

        <template v-slot:[`item.total_price`]="{ item }">
          <projects-list-price
            :project-health="item.project_health"
            :project-id="item.id"
            :project-price="item.total_price"
          ></projects-list-price>
        </template>

        <template v-slot:[`item.jobs`]="{ item }">
          <projects-list-jobs
            :jobs="item.jobs"
            :project-id="item.id"
          ></projects-list-jobs>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | unixToFormat('DD/MM/YY') }}
        </template>

        <template v-slot:[`item.start_date`]="{ item }">
          <projects-list-dates
            :tooltip-id="`project-${item.id}-start-date`"
            tooltip-text="Add project start date"
            :project-id="item.id"
            :default-date="item.start_date"
            :default-start-date="item.start_date"
            :default-end-date="item.due_date"
          ></projects-list-dates>
        </template>

        <template v-slot:[`item.due_date`]="{ item }">
          <projects-list-dates
            :tooltip-id="`project-${item.id}-end-date`"
            tooltip-text="Add project end date"
            :project-id="item.id"
            :default-date="item.due_date"
            :default-start-date="item.start_date"
            :default-end-date="item.due_date"
          ></projects-list-dates>
        </template>

        <template v-slot:[`item.hours`]="{ item }">
          <projects-list-hours
            :budgeted-hours="item.budgeted_hours"
            :project-health="item.project_health"
            :project-id="item.id"
          ></projects-list-hours>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <projects-list-actions :project="item"></projects-list-actions>
        </template>
      </v-data-table>

      <div
        v-show="!projectsListLoading && hasProjects"
        :class="[
          'vms-projects__pagination vd-padding-top-small vd-padding-bottom-large vd-margin-right-80',
          isSmallScreen ? 'vms-projects__pagination--center' : '',
        ]"
      >
        <div class="vms-pagination--bottom">
          <pagination-component
            :total-rows="projectsList.meta.total"
            :page="currentPage"
            :per-page="selectedPerPage"
            :last-page="projectsList.meta.last_page"
            :show-page-in-route="pagination.showPageInRoute"
            :limit-options="pagination.limitOptions"
            @update:page="currentPageUpdate"
            @update:per_page="projectsListPerPageUpdate"
          ></pagination-component>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Vuetify from 'vuetify'
import PaginationComponent from '@components/pagination/pagination'
import FormMixin from '@mixins/forms-mixin'
import FiltersMixin from '@mixins/filters-mixin'
import ProjectsListProductType from '@views/projects/projects-list/projects-list-row/projects-list-product-type'
import ProjectsListStaffDisplay from '@views/projects/projects-list/projects-list-row/projects-list-staff/projects-list-staff-display'
import ProjectsListCollaborators from '@views/projects/projects-list/projects-list-row/projects-list-staff/projects-list-collaborators'
import ProjectsListStatus from '@views/projects/projects-list/projects-list-row/projects-list-status'
import ProjectsListJobs from '@views/projects/projects-list/projects-list-row/projects-list-jobs'
import ProjectsListHours from '@views/projects/projects-list/projects-list-row/projects-list-hours'
import ProjectsListPrice from '@views/projects/projects-list/projects-list-row/projects-list-price'
import ProjectsListDates from '@views/projects/projects-list/projects-list-row/projects-list-dates'
import ProjectsListNotes from '@views/projects/projects-list/projects-list-row/projects-list-notes'
import ProjectsListName from '@views/projects/projects-list/projects-list-row/projects-list-name'
import ProjectsListOffice from '@views/projects/projects-list/projects-list-row/projects-list-office'
import ProjectsListActions from '@views/projects/projects-list/projects-list-row/projects-list-actions/projects-list-actions'
import ProjectsListFilter from '@views/projects/projects-list/projects-list-filter/projects-list-filter'
import ProjectsListNoResults from '@components/empty-states/projects/projects-list-no-results'
import SearchComponent from '@components/search/search'
import ApiCustomIncludes from '@configs/api-custom-includes'
import { size, filter, includes, isEmpty } from 'lodash'

export default {
  components: {
    PaginationComponent,
    ProjectsListProductType,
    ProjectsListStaffDisplay,
    ProjectsListCollaborators,
    ProjectsListStatus,
    ProjectsListJobs,
    ProjectsListHours,
    ProjectsListPrice,
    ProjectsListDates,
    ProjectsListNotes,
    ProjectsListName,
    ProjectsListOffice,
    ProjectsListActions,
    ProjectsListFilter,
    ProjectsListNoResults,
    SearchComponent,
  },

  vuetify: new Vuetify(),

  mixins: [FormMixin, FiltersMixin],

  data() {
    return {
      search: '',
      options: {},
      pagination: {
        limitOptions: [10, 25, 50, 75, 100],
        showPageInRoute: false,
      },
      showFilters: false,
      // based on <= 768 breakpoint
      tableBreakpoint: 769,
    }
  },

  computed: {
    ...mapGetters({
      projectsList: 'projects/projectsList',
      projectsListLoading: 'projects/projectsListLoading',
      projectsFilter: 'projects/projectsFilter',
      windowWidth: 'common/windowWidth',
      isMobileScreen: 'common/isMobileScreen',
      selectedPerPage: 'projects/selectedPerPage',
      currentPage: 'projects/currentPage',
    }),

    defaultTableHeaders() {
      return [
        {
          text: 'Project ID',
          sortable: true,
          filterable: true,
          value: 'id',
          align: 'center',
        },
        {
          text: 'Project Name',
          sortable: true,
          filterable: true,
          value: 'name',
          width: '15%',
        },
        {
          text: 'Product Type',
          sortable: true,
          filterable: true,
          value: 'product_type.name',
          align: 'center',
        },
        {
          text: 'Office',
          sortable: true,
          filterable: true,
          value: 'office.name',
          width: '15%',
        },
        {
          text: 'Relationship Manager',
          sortable: true,
          filterable: true,
          value: 'account_manager.full_name',
          width: '7%',
          align: 'center',
        },
        {
          text: 'Project Manager',
          sortable: true,
          filterable: true,
          value: 'producer.full_name',
          width: '7%',
          align: 'center',
        },
        {
          text: 'Collaborators',
          sortable: false,
          filterable: true,
          value: 'collaborators',
          width: '12%',
          align: 'center',
        },
        {
          text: 'Status',
          sortable: true,
          filterable: true,
          value: 'project_status.name',
          width: '7%',
        },
        {
          text: 'Notes',
          sortable: false,
          filterable: true,
          value: 'notes',
          width: '20%',
        },
        {
          text: 'Price',
          sortable: true,
          filterable: true,
          value: 'total_price',
          align: 'center',
        },
        {
          text: 'Jobs',
          sortable: false,
          filterable: false,
          value: 'jobs',
          align: 'center',
          width: '30%',
        },
        {
          text: 'Created',
          sortable: true,
          filterable: false,
          value: 'created_at',
          width: '5%',
          align: 'center',
        },
        {
          text: 'Start Date',
          sortable: true,
          filterable: false,
          value: 'start_date',
          align: 'center',
        },
        {
          text: 'Est. Comp. Date',
          sortable: true,
          filterable: false,
          value: 'due_date',
          align: 'center',
        },
        {
          text: 'Hours',
          sortable: false,
          filterable: false,
          value: 'hours',
          width: '5%',
          align: 'center',
        },
        {
          text: 'Actions',
          sortable: false,
          filterable: false,
          value: 'actions',
          align: 'center',
        },
      ]
    },

    projectHeaders() {
      if (!this.isMobileScreen) {
        return this.defaultTableHeaders
      }

      return filter(this.defaultTableHeaders, (header) =>
        includes(
          [
            'Project ID',
            'Project Name',
            'Office',
            'Status',
            'Price',
            'Actions',
          ],
          header.text
        )
      )
    },

    isSmallScreen() {
      return this.windowWidth <= 900
    },

    isMediumScreen() {
      return this.windowWidth < 1440
    },

    showHorizontalSeparator() {
      return (
        this.windowWidth < 1750 ||
        (this.isMediumScreen && this.windowWidth <= 1218 && !this.isSmallScreen)
      )
    },

    searchClass() {
      if (this.isSmallScreen) {
        return 'vms-projects__search vms-projects__search--fill vd-margin-right-small'
      }

      if (this.showHorizontalSeparator) {
        return 'vms-projects__search vms-projects__search--fill vd-margin-right-medium'
      }

      return 'vms-projects__search vd-margin-right-medium'
    },

    controlsClass() {
      let controlsClass = ''

      if (!this.isSmallScreen) {
        if (this.showHorizontalSeparator) {
          controlsClass = 'vd-padding-right-80'
        } else if (this.isMediumScreen) {
          controlsClass = 'vd-padding-right-medium'
        }
      }

      return controlsClass
    },

    projectsListFilterClass() {
      if (this.isSmallScreen) {
        return 'vms-projects__filters--mobile row no-gutters vd-padding-top-small'
      }

      let filterClass = ''

      if (this.isMediumScreen) {
        filterClass += ' vms-projects__filters--icon-only'
      }

      if (this.showHorizontalSeparator) {
        filterClass += ' vms-projects__filters--to-right'
      }

      return filterClass
    },

    filterIconClass() {
      if (this.showFilters) {
        return this.hasSelectedFilter ? 'vd-icon-close-white' : 'vd-icon-close'
      }

      return this.hasSelectedFilter ? 'vd-icon-filter-white' : 'vd-icon-filter'
    },

    hasSelectedFilter() {
      const {
        from,
        to,
        users,
        statuses,
        product_types,
        project_type,
      } = this.projectsFilter
      return (
        !isEmpty(from) ||
        !isEmpty(to) ||
        !isEmpty(users) ||
        !isEmpty(statuses) ||
        !isEmpty(product_types) ||
        project_type === 'my_projects'
      )
    },

    hasProjects() {
      return size(this.projectsList.data) > 0
    },
  },

  watch: {
    options: {
      handler() {
        if (size(this.options.sortBy) > 0) {
          this.searchAndSortProjects()
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      getAllProjects: 'projects/getAllProjects',
      updateProjectSortBy: 'projects/updateProjectSortBy',
      updateProjectSort: 'projects/updateProjectSort',
      updateProjectSearchKeyword: 'projects/updateProjectSearchKeyword',
      updateProjectPerPage: 'projects/updateProjectPerPage',
      setProjectsFilterCookies: 'projects/setProjectsFilterCookies',
      updateCurrentPage: 'projects/updateCurrentPage',
    }),

    getSortByName(sortBy) {
      switch (sortBy) {
        case 'product_type.name': {
          return 'product_type'
        }
        case 'office.name': {
          return 'office'
        }
        case 'account_manager.full_name': {
          return 'relationship_manager'
        }
        case 'producer.full_name': {
          return 'project_manager'
        }
        case 'project_status.name': {
          return 'status'
        }
        default:
          return sortBy
      }
    },

    handleAddNewProject() {
      this.$router.push({
        name: 'project.create',
      })
    },

    searchAndSortProjects(page) {
      const sortBy = this.options.sortBy[0]
      const sort = sortBy ? (this.options.sortDesc[0] ? 'desc' : 'asc') : 'desc'
      const updatedPage = page ? page : 1

      this.updateProjectSortBy(this.getSortByName(sortBy))

      this.updateProjectSort(sort)

      this.setProjectsFilterCookies({
        type: 'sort',
        value: {
          sortBy: this.getSortByName(sortBy),
          direction: sort,
        },
      })

      this.updateProjectSearchKeyword(this.search)

      this.getAllProjects({
        include: ApiCustomIncludes.projectsList,
        search: this.search,
        sortBy: this.getSortByName(sortBy),
        sort: sort,
        page: updatedPage,
      })

      this.updateCurrentPage(updatedPage)
    },

    currentPageUpdate(page) {
      if (page !== this.$route?.query.page) {
        // check if the selected page is not more than the meta last page
        const isSelectedPageValid = page <= this.projectsList.meta.last_page

        this.searchAndSortProjects(
          isSelectedPageValid ? page : this.projectsList.meta.last_page
        )
      }

      this.$emit('update:pagination')
    },

    projectsListPerPageUpdate(perPage) {
      this.updateProjectPerPage(perPage)

      this.searchAndSortProjects()
      this.$emit('update:pagination')
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
@import '@styles/views/projects/projects-list';
</style>
