<template>
  <div>
    <div v-if="isRelationshipManager">
      <inline-edit-loader
        v-if="showInlineLoader('relationship_manager')"
      ></inline-edit-loader>

      <user-inline-editor
        v-else
        :dropdown-select-list="accountManagers"
        :dropdown-select-config="dropdownConfig"
        custom-input-div-class="vms-projects__list--users__edit vd-margin-small"
        input-placeholder="Select a Relationship Manager"
        @handle:inline-user-save="handleRelationshipMangerUpdate"
      >
        <staff-popover
          slot="inline-body"
          :selected-user="relationshipManager"
          :tooltip-id="`rm-${relationshipManager.id}-project-${projectId}`"
          class="vd-padding-extra-small"
          :label-avatar-font-size="45"
          label-avatar-class="vms-projects__list--users-avatar"
          label-avatar-image-class="vms-projects__list--users-profile"
          popover-avatar-image-class="vms-projects__list--users-profile__info"
        ></staff-popover>
      </user-inline-editor>
    </div>

    <div v-if="isProjectManager">
      <inline-edit-loader
        v-if="showInlineLoader('project_manager')"
      ></inline-edit-loader>
      <user-inline-editor
        v-else
        :dropdown-select-list="producers"
        :dropdown-select-config="dropdownConfig"
        custom-input-div-class="vms-projects__list--users__edit vd-margin-small"
        input-placeholder="Select a Project Manager"
        @handle:inline-user-save="handleProjectMangerUpdate"
      >
        <staff-popover
          slot="inline-body"
          :selected-user="projectManager"
          :tooltip-id="`pm-${projectManager.id}-project-${projectId}`"
          class="vd-padding-extra-small"
          :label-avatar-font-size="45"
          label-avatar-class="vms-projects__list--users-avatar"
          label-avatar-image-class="vms-projects__list--users-profile"
          popover-avatar-image-class="vms-projects__list--users-profile__info"
        ></staff-popover>
      </user-inline-editor>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, find, isNil } from 'lodash'
import StaffPopover from '@components/staff-popover/staff-popover'
import UserInlineEditor from '@components/inline-edit/user-inline-editor'
import ApiCustomIncludes from '@configs/api-custom-includes'
import InlineEditLoader from '@components/inline-edit/inline-edit-loader'

export default {
  components: {
    StaffPopover,
    UserInlineEditor,
    InlineEditLoader,
  },

  props: {
    projectId: {
      type: Number,
      required: true,
      default: null,
    },

    type: {
      type: String,
      required: true,
      default: '',
    },

    relationshipManager: {
      type: [Object, Array],
      required: false,
      default: () => {
        return {}
      },
    },

    projectManager: {
      type: [Object, Array],
      required: false,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters({
      accountManagers: 'user/accountManagers',
      producers: 'user/producers',
      selectedProjectId: 'projects/selectedProjectId',
      editProjectLoading: 'project/editProjectLoading',
      selectedFieldsForUpdating: 'projects/selectedFieldsForUpdating',
    }),

    dropdownConfig() {
      return {
        option_value: 'id',
        option_text: 'full_name',
      }
    },

    isRelationshipManager() {
      return (
        this.type === 'relationship_manager' &&
        !isEmpty(this.relationshipManager)
      )
    },

    isProjectManager() {
      return this.type === 'project_manager' && !isEmpty(this.projectManager)
    },
  },

  methods: {
    ...mapActions({
      inLineUpdateProjectDetails: 'projects/inLineUpdateProjectDetails',
    }),

    showInlineLoader(fieldType) {
      const isUserFieldSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.projectId &&
            selectedField.field === fieldType
          )
        }
      )

      return (
        this.editProjectLoading &&
        this.selectedProjectId === this.projectId &&
        !isNil(isUserFieldSelected)
      )
    },

    handleRelationshipMangerUpdate(user) {
      this.inLineUpdateProjectDetails({
        projectId: this.projectId,
        includes: ApiCustomIncludes.projectsList,
        selectedField: {
          project_id: this.projectId,
          field: 'relationship_manager',
        },
        payload: {
          account_manager_id: user.id,
        },
      })
    },

    handleProjectMangerUpdate(user) {
      this.inLineUpdateProjectDetails({
        projectId: this.projectId,
        includes: ApiCustomIncludes.projectsList,
        selectedField: { project_id: this.projectId, field: 'project_manager' },
        payload: {
          producer_id: user.id,
        },
      })
    },
  },
}
</script>
