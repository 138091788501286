var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"container-fluid vd-background-aqua vd-padding-small vd-position-relative"},[_c('div',{staticClass:"vms-projects__new vd-position-relative",on:{"click":_vm.handleAddNewProject}},[_c('div',{class:[
          'vd-icon-plus-round-blue vms-projects__new--icon vd-position-absolute',
          _vm.isSmallScreen ? 'vms-projects__new--icon--mobile' : '' ],attrs:{"id":"openNewProjectFormDiv"}})]),_c('div',{staticClass:"row no-gutters vms-projects__controls-wrapper"},[(!_vm.projectsListLoading)?_c('div',{staticClass:"col-auto vms-projects__controls"},[_c('div',{class:['row no-gutters', _vm.controlsClass]},[_c('search-component',{class:_vm.searchClass,attrs:{"search-text":_vm.search},on:{"input:search":function($event){_vm.search = $event},"enter:search":function($event){return _vm.searchAndSortProjects()}}}),(_vm.isSmallScreen)?_c('button',{class:[
              'vms-projects__btn-filter vd-border-width-1 vd-padding-small',
              _vm.hasSelectedFilter
                ? 'vd-background-light-blue vd-border-light-blue '
                : 'vd-background-white vd-border-grey' ],attrs:{"id":"toggleFilterButton"},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('span',{class:['vms-projects__btn-filter--icon', _vm.filterIconClass]})]):_vm._e(),(!_vm.isSmallScreen || _vm.showFilters)?_c('projects-list-filter',{class:_vm.projectsListFilterClass,attrs:{"custom-active-class":_vm.isMediumScreen || _vm.isSmallScreen
                ? 'vms-filters__dropdown--active vd-background-light-blue vd-border-light-blue vd-white'
                : '',"hide-selected-text":_vm.isMediumScreen && !_vm.isSmallScreen,"is-mobile-filter":_vm.isSmallScreen}}):_vm._e()],1)]):_vm._e(),(!_vm.projectsListLoading && _vm.showHorizontalSeparator)?_c('hr',{staticClass:"col-12 vd-border-grey vd-margin-top-small vd-margin-bottom-medium"}):_vm._e(),_c('div',{class:[
          'vms-projects__pagination col-auto vd-margin-right-80',
          !_vm.projectsListLoading ? 'vd-margin-bottom-extra-small' : '',
          _vm.isSmallScreen ? 'vms-projects__pagination--center' : '' ]},[_c('div',{staticClass:"vms-pagination"},[_c('pagination-component',{attrs:{"total-rows":_vm.projectsList.meta.total,"page":_vm.currentPage,"per-page":_vm.selectedPerPage,"last-page":_vm.projectsList.meta.last_page,"show-page-in-route":_vm.pagination.showPageInRoute,"limit-options":_vm.pagination.limitOptions},on:{"update:page":_vm.currentPageUpdate,"update:per_page":_vm.projectsListPerPageUpdate}})],1)])])]),_c('div',{class:[
      'vms-projects__table--container',
      _vm.isMobileScreen ? 'vms-projects__table--container__mobile' : '' ]},[_c('v-data-table',{ref:"projectsListTable",class:[
        'vms-projects__table vd-padding-medium',
        !_vm.hasProjects ? 'vms-projects__table--empty' : '' ],attrs:{"headers":_vm.projectHeaders,"items":_vm.projectsList.data,"options":_vm.options,"server-items-length":_vm.projectsList.meta.total,"loading":_vm.projectsListLoading,"fixed-header":!_vm.isMobileScreen,"mobile-breakpoint":_vm.tableBreakpoint,"loading-text":"Loading Projects...","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"vd-a",attrs:{"to":{
            name: 'project.details.summary',
            params: { projectId: item.id },
          }}},[_vm._v("#"+_vm._s(item.id))])]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-name',{attrs:{"project-name":item.name,"project-id":item.id}})]}},{key:"item.product_type.name",fn:function(ref){
          var item = ref.item;
return [(item.product_type)?_c('projects-list-product-type',{attrs:{"product-type":item.product_type,"project-id":item.id}}):_vm._e()]}},{key:"item.office.name",fn:function(ref){
          var item = ref.item;
return [(item.office)?_c('projects-list-office',{attrs:{"office-name":item.office.name,"project-id":item.id}}):_vm._e()]}},{key:"item.account_manager.full_name",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-staff-display',{attrs:{"type":"relationship_manager","project-id":item.id,"relationship-manager":item.account_manager}})]}},{key:"item.producer.full_name",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-staff-display',{attrs:{"type":"project_manager","project-id":item.id,"project-manager":item.producer}})]}},{key:"item.collaborators",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-collaborators',{attrs:{"project-id":item.id,"director":item.director,"production-coordinator":item.production_coordinator}})]}},{key:"item.project_status.name",fn:function(ref){
          var item = ref.item;
return [(item.project_status_id)?_c('projects-list-status',{attrs:{"project-status":item.project_status,"project-id":item.id}}):_vm._e()]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-notes',{attrs:{"notes":item.notes,"project-id":item.id}})]}},{key:"item.total_price",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-price',{attrs:{"project-health":item.project_health,"project-id":item.id,"project-price":item.total_price}})]}},{key:"item.jobs",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-jobs',{attrs:{"jobs":item.jobs,"project-id":item.id}})]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unixToFormat")(item.created_at,'DD/MM/YY'))+" ")]}},{key:"item.start_date",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-dates',{attrs:{"tooltip-id":("project-" + (item.id) + "-start-date"),"tooltip-text":"Add project start date","project-id":item.id,"default-date":item.start_date,"default-start-date":item.start_date,"default-end-date":item.due_date}})]}},{key:"item.due_date",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-dates',{attrs:{"tooltip-id":("project-" + (item.id) + "-end-date"),"tooltip-text":"Add project end date","project-id":item.id,"default-date":item.due_date,"default-start-date":item.start_date,"default-end-date":item.due_date}})]}},{key:"item.hours",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-hours',{attrs:{"budgeted-hours":item.budgeted_hours,"project-health":item.project_health,"project-id":item.id}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('projects-list-actions',{attrs:{"project":item}})]}}],null,true)},[_c('template',{slot:"no-data"},[_c('projects-list-no-results')],1)],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.projectsListLoading && _vm.hasProjects),expression:"!projectsListLoading && hasProjects"}],class:[
        'vms-projects__pagination vd-padding-top-small vd-padding-bottom-large vd-margin-right-80',
        _vm.isSmallScreen ? 'vms-projects__pagination--center' : '' ]},[_c('div',{staticClass:"vms-pagination--bottom"},[_c('pagination-component',{attrs:{"total-rows":_vm.projectsList.meta.total,"page":_vm.currentPage,"per-page":_vm.selectedPerPage,"last-page":_vm.projectsList.meta.last_page,"show-page-in-route":_vm.pagination.showPageInRoute,"limit-options":_vm.pagination.limitOptions},on:{"update:page":_vm.currentPageUpdate,"update:per_page":_vm.projectsListPerPageUpdate}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }