<template>
  <div class="vd-border-light-grey vd-border-width-0 vd-border-bottom-width-1">
    <div v-for="(subMenu, index) in subMenuList" :key="index">
      <a
        v-if="subMenu.hasPermission && subMenu.route.isExternal"
        :href="subMenu.route.url"
        class="vms-side-menu__sub-menu vd-cursor-pointer vd-padding-small vd-h5 vd-black vd-a"
      >
        <span class="vd-h4 vd-padding-left-medium">{{ subMenu.label }}</span>
      </a>
      <router-link
        v-else-if="subMenu.hasPermission && !subMenu.route.isExternal"
        :to="{ name: subMenu.route.name }"
        class="vms-side-menu__sub-menu vd-cursor-pointer vd-padding-small vd-h5 vd-black vd-a"
      >
        <span class="vd-h4 vd-padding-left-medium">{{ subMenu.label }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subMenuList: {
      type: Array,
      required: true,
    },
  },
}
</script>
