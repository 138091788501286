<template>
  <inline-edit-loader
    v-if="showLoader"
    loading-text="Loading..."
  ></inline-edit-loader>
  <div v-else class="vd-position-relative">
    <div
      v-for="(job, index) in jobs"
      :key="index"
      class="vd-padding-extra-small vd-padding-top-small vd-padding-bottom-small vd-display-inline-block"
    >
      <div
        :class="['vms-projects__list--jobs-icon', jobTypeIcon(job.type)]"
      ></div>
      <div>
        <span class="vd-h5">{{ job.in_progress }}/{{ job.total_jobs }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import InlineEditLoader from '@components/inline-edit/inline-edit-loader'
import { mapGetters } from 'vuex'
import { isNil, find } from 'lodash'

export default {
  components: {
    InlineEditLoader,
  },

  props: {
    jobs: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
    projectId: {
      type: Number,
      required: true,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      selectedFieldsForUpdating: 'projects/selectedFieldsForUpdating',
      editProjectLoading: 'project/editProjectLoading',
    }),

    showLoader() {
      const isJobsSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.projectId &&
            selectedField.field === 'jobs'
          )
        }
      )

      return !isNil(isJobsSelected) && this.editProjectLoading
    },
  },

  methods: {
    jobTypeIcon(jobType) {
      switch (jobType) {
        case 'pre_production':
          return 'vd-icon-clipboard'
        case 'shoot':
          return 'vd-icon-camcorder-violet'
        case 'delivery':
          return 'vd-icon-camera-blue'
        default:
          return ''
      }
    },
  },
}
</script>
