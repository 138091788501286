var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showInlineLoader)?_c('inline-edit-loader'):_c('user-inline-editor',{attrs:{"dropdown-select-list":_vm.producers,"dropdown-select-config":_vm.dropdownConfig,"custom-input-div-class":"vms-projects__list--users__edit vd-margin-small","input-placeholder":"Select Collaborators"},on:{"handle:inline-user-save":_vm.handleUserUpdate}},[_c('div',{staticClass:"vd-margin-small",attrs:{"slot":"inline-body-header"},slot:"inline-body-header"},[(_vm.hasSelectedUser)?_c('div',{staticClass:"vd-background-lightest-grey vd-border-radius-100 vd-position-relative vd-padding-small"},[_c('user-profile',{staticClass:"vd-margin-right-small",attrs:{"user":_vm.selectedUser,"custom-image-class":"vms-projects__list--users-profile","custom-avatar-class":"vms-projects__list--users-avatar","custom-avatar-font-size":45}}),_c('span',[_vm._v(_vm._s(_vm.selectedUser.full_name))]),_c('span',{staticClass:"vd-position-absolute vms-projects__list--users__edit--remove",on:{"click":_vm.handleRemoveUser}},[_c('span',{staticClass:"vd-icon-close-circle vms-projects__list--users__edit--remove-icon"})])],1):_vm._e(),_c('div',{class:[
        'vd-padding-top-small',
        _vm.hasSelectedUser
          ? 'vd-margin-top-medium vd-border-lighter-grey vms-projects__list--users__edit--divider'
          : '' ]},[_c('div',{staticClass:"row no-gutters"},[_c('div',{class:[
            'vd-margin-right-medium vd-padding-bottom-extra-small',
            _vm.isProductionCoordinatorSelected
              ? 'vd-border-blue vms-projects__list--users__edit--selected'
              : 'vd-blue vms-projects__list--users__edit--unselected' ],on:{"click":function($event){_vm.selectedCollaboratorType = 'production-coordinator'}}},[_vm._v(" Production Coordinator ")]),_c('div',{class:[
            'vd-padding-bottom-extra-small',
            _vm.isDirectorSelected
              ? 'vd-border-blue vms-projects__list--users__edit--selected'
              : 'vd-blue vms-projects__list--users__edit--unselected' ],on:{"click":function($event){_vm.selectedCollaboratorType = 'director'}}},[_vm._v(" Director ")])])])]),_c('div',{staticClass:"vd-position-relative",attrs:{"slot":"inline-body"},slot:"inline-body"},[(_vm.isDirector || _vm.isProductionCoordinator)?_c('div',[(_vm.isProductionCoordinator)?_c('staff-popover',{staticClass:"vd-padding-extra-small vd-display-inline-block",attrs:{"selected-user":_vm.productionCoordinator,"tooltip-id":("product-coordinator-" + (_vm.productionCoordinator.id) + "-project-" + _vm.projectId),"label-avatar-font-size":45,"label-avatar-class":"vms-projects__list--users-avatar","label-avatar-image-class":"vms-projects__list--users-profile","popover-avatar-image-class":"vms-projects__list--users-profile__info"}}):_vm._e(),(_vm.isDirector)?_c('staff-popover',{staticClass:"vd-padding-extra-small vd-display-inline-block",attrs:{"selected-user":_vm.director,"tooltip-id":("director-" + (_vm.director.id) + "-project-" + _vm.projectId),"label-avatar-font-size":45,"label-avatar-class":"vms-projects__list--users-avatar","label-avatar-image-class":"vms-projects__list--users-profile","popover-avatar-image-class":"vms-projects__list--users-profile__info"}}):_vm._e()],1):_c('div',[_c('text-popover',{staticClass:"vd-display-inline-block",attrs:{"tooltip-text":"Add Collaborators","tooltip-placement":"right","tooltip-icon":"vd-icon-plus-circle","tooltip-id":("projectAddCollaboratorTooltip-" + _vm.projectId)}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }