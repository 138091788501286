<template>
  <div class="vms-projects__list--price" @click="handleShowProjectHealth">
    <text-popover
      :tooltip-id="`projectPriceTooltip-${projectId}`"
      custom-tooltip-class="vd-padding-small vd-margin-top-small"
      tooltip-trigger="click blur"
      tooltip-placement="right"
      tooltip-text="Price"
      :show-icon="false"
      :tooltip-label="tooltipLabel"
    >
      <div
        slot="popover-body"
        class="vd-padding-small vms-projects__list--price-body"
      >
        <div v-if="isProjectHealthLoading">Loading..</div>
        <div v-else>
          <div class="vd-h5">
            <strong>Price</strong>
          </div>

          <div class="row no-gutters">
            <div class="col-8">Project management:</div>
            <div class="vd-text-align-right col-4">
              {{ totalProjectManagementValue | moneyFormat }}
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-8">Production cost:</div>
            <div class="vd-text-align-right col-4">
              {{ totalProductionCost | moneyFormat }}
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-8">Disbursements:</div>
            <div class="vd-text-align-right col-4">
              {{ projectHealth.total_disbursement_cost | moneyFormat }}
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-8">
              <strong>Total price:</strong>
            </div>
            <div class="vd-text-align-right col-4">
              <strong>{{ projectPrice | moneyFormat }}</strong>
            </div>
          </div>
        </div>
      </div>
    </text-popover>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import FiltersMixin from '@mixins/filters-mixin'
import { isEmpty } from 'lodash'

export default {
  components: {
    TextPopover,
  },

  mixins: [FiltersMixin],

  props: {
    projectHealth: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    projectId: {
      type: Number,
      required: true,
      default: null,
    },

    projectPrice: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  computed: {
    ...mapGetters({
      defaultGstValue: 'project/project-health/defaultGstValue',
      isProjectHealthLoading: 'projects/projectHealthLoading',
    }),

    totalProjectManagementValue() {
      return this.projectHealth.total_production_cost / this.defaultGstValue
    },

    totalProductionCost() {
      return (
        this.projectHealth.total_production_cost -
        this.totalProjectManagementValue
      )
    },

    tooltipLabel() {
      return `<span>${this.$options.filters.moneyFormat(
        this.projectPrice
      )}</span>`
    },
  },

  methods: {
    ...mapActions({
      getProjectHealth: 'projects/getProjectHealth',
    }),

    handleShowProjectHealth() {
      if (isEmpty(this.projectHealth)) {
        this.getProjectHealth(this.projectId)
      }
    },
  },
}
</script>
