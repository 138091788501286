<template>
  <default-modal
    :class="[
      'show vd-text-align-left',
      isMobileScreen ? 'vms-modal--fullscreen' : '',
    ]"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header vms-modal__header">
      <div>
        <div class="vd-h4 vd-black">
          <i class="vd-icon icon-box-archive vd-margin-right-extra-small"></i
          >Project Archive
        </div>
        <div class="vd-h5 vd-dark-grey">
          Before archiving the project please provide some additional
          information
        </div>
      </div>
      <div class="vms-modal__header--close close vd-black">
        <span @click.prevent="handleCancel">x</span>
      </div>
    </div>

    <div slot="modal-body" class="modal-body">
      <div
        :class="[
          'vd-field vd-required vd-margin-bottom-large',
          footagePolicy ? 'vd-input-valid' : '',
        ]"
      >
        <label>How should we handle this projects footage?</label>
        <model-list-select
          v-model="footagePolicy"
          :list="footagePolicies"
          option-value="id"
          option-text="name"
          placeholder="Select footage policy"
        ></model-list-select>
      </div>

      <div
        v-if="isLongTermArchive"
        :class="[
          'vd-field vd-required vd-margin-bottom-large',
          reason ? 'vd-input-valid' : '',
        ]"
      >
        <label>Provide reason for archiving:</label>
        <textarea
          v-model="reason"
          placeholder="Please explain why you need to archive this projects files, if you don't absolutely have to we would prefer to delete"
          class="vms-input__textarea vd-border-light-grey vd-border-radius-6 vd-padding-medium"
        />
      </div>

      <div
        v-if="isPolicyKeep"
        :class="[
          'vd-field vd-required vd-margin-bottom-large',
          reason ? 'vd-input-valid' : '',
        ]"
      >
        <label>These assets will be used in the following project(s):</label>
        <textarea
          v-model="reason"
          placeholder="Detail any projects you know of that will need to use this project's assets."
          class="vms-input__textarea vd-border-light-grey vd-border-radius-6 vd-padding-medium"
        />
      </div>

      <div class="vd-margin-top-large">
        <em class="vd-red vd-h5">
          * Archiving and deleting of footage will not occur for at least 3
          months after project archival
        </em>
      </div>
    </div>

    <div slot="modal-footer" class="modal-footer">
      <div class="vd-text-align-right">
        <button
          class="vd-btn-small vd-btn-grey vd-margin-right-small"
          @click.prevent="handleCancel"
        >
          Cancel
        </button>
        <button
          class="vd-btn-small vd-background-red vd-border-red vd-white"
          :disabled="isSaveDisabled"
          @click="handleSave"
        >
          {{ projectDetailsLoading ? 'Archiving...' : 'Archive' }}
        </button>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { find, filter } from 'lodash'
import DefaultModal from '@components/modal/default-modal'
import Vuetify from 'vuetify'
import { ModelListSelect } from 'vue-search-select'
import FootagePolicyTypeConstants from '@configs/footage-policy-types'

export default {
  components: {
    DefaultModal,
    ModelListSelect,
  },

  vuetify: new Vuetify(),

  props: {
    project: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    updateProjectList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      footagePolicyProvided: null,
      footagePolicy: null,
      reason: null,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      projectDetailsLoading: 'project/projectDetailsLoading',
    }),

    footagePolicyProvidedOptions() {
      return [
        { id: 'y', name: 'Yes' },
        { id: 'n', name: 'No' },
      ]
    },

    isLongTermArchive() {
      const longTermArchive = find(this.footagePolicies, {
        key: 'long-term-archive',
      })

      if (!longTermArchive) {
        return false
      }

      return this.footagePolicy === longTermArchive.id
    },

    isPolicyKeep() {
      // TODO: Change this to use ID instead of key
      const policyKeep = find(this.footagePolicies, {
        key: 'keep',
      })

      if (!policyKeep) {
        return false
      }

      return this.footagePolicy === policyKeep.id
    },

    footagePolicies() {
      if (this.project.office.company.has_footage_policy_document) {
        return FootagePolicyTypeConstants.policies
      }

      // TODO: Change this to use ID instead of key
      return filter(
        FootagePolicyTypeConstants.policies,
        (policy) => policy.key !== 'follow-policy'
      )
    },

    isSaveDisabled() {
      return (
        this.footagePolicy === null ||
        this.footagePolicyProvided === null ||
        (this.isLongTermArchive && this.reason === null) ||
        (this.isPolicyKeep && this.reason === null)
      )
    },
  },

  mounted() {
    this.footagePolicy = this.project.footage_policy_type_id
    this.footagePolicyProvided = 'n'

    if (this.project.office.company.has_footage_policy_document) {
      this.footagePolicyProvided = 'y'

      // TODO: Change this to use ID instead of key
      if (!this.project.footage_policy_type_id) {
        this.footagePolicy = find(this.footagePolicies, {
          key: 'follow-policy',
        }).id
      }
    }
  },

  methods: {
    ...mapActions({
      archiveProject: 'project/archiveProject',
      toggleArchiveProjectModal: 'project/toggleArchiveProjectModal',
    }),

    handleCancel() {
      this.toggleArchiveProjectModal(false)
    },

    handleSave() {
      this.archiveProject({
        projectId: this.project.id,
        name: this.project.name,
        updateProjectList: this.updateProjectList,
        selectedField: {
          project_id: this.project.id,
          field: 'status',
        },
        footagePolicyId: this.footagePolicy,
        footagePolicyReason: this.reason,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vue-search-select';
@import '@styles/plugins/vuetify';
</style>
