<template>
  <div v-if="isLoggedIn" class="main-layout">
    <header-layout></header-layout>

    <side-menu-layout></side-menu-layout>

    <slot name="content-body"></slot>

    <slot name="content-footer"></slot>
  </div>
</template>

<script>
import HeaderLayout from '@layouts/header'
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import SideMenuLayout from '@layouts/side-menu/side-menu'

export default {
  components: {
    HeaderLayout,
    SideMenuLayout,
  },

  data() {
    return {
      debouncedUpdateWindowSizeMethod: null,
    }
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },

  created() {
    const vm = this

    // Avoid costly calculation and store updates: https://lodash.com/docs/4.17.15#debounce
    vm.debouncedUpdateWindowSizeMethod = debounce(() => {
      vm.updateWindowSizes({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 150)

    window.addEventListener('resize', vm.debouncedUpdateWindowSizeMethod, false)

    vm.updateWindowSizes({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  },

  beforeDestroy() {
    window.removeEventListener(
      'resize',
      this.debouncedUpdateWindowSizeMethod,
      false
    )
  },

  methods: {
    ...mapActions({
      updateWindowSizes: 'common/windowResized',
    }),
  },
}
</script>
