<template>
  <div class="vms-projects__filters">
    <project-type-filter
      :hide-selected-text="hideSelectedText"
      :custom-active-class="customActiveClass"
      :class="{ 'col-12 vd-margin-bottom-small': isMobileFilter }"
      @handle:filter-select="setProjectsFilterCookies"
    ></project-type-filter>
    <project-created-filter
      :hide-selected-text="hideSelectedText"
      :custom-active-class="customActiveClass"
      :custom-dropdown-list-class="
        isMobileFilter
          ? 'vms-filters__dropdown--fullscreen vd-margin-top-0'
          : ''
      "
      :class="{ 'col-12 vd-margin-bottom-small': isMobileFilter }"
      @handle:filter-select="setProjectsFilterCookies"
    ></project-created-filter>
    <project-user-filter
      :hide-selected-text="hideSelectedText"
      :custom-active-class="customActiveClass"
      :custom-dropdown-list-class="
        isMobileFilter
          ? 'vms-filters__dropdown--fullscreen vd-margin-top-0'
          : ''
      "
      :class="{ 'col-12 vd-margin-bottom-small': isMobileFilter }"
      @handle:filter-select="setProjectsFilterCookies"
    ></project-user-filter>
    <project-status-filter
      :hide-selected-text="hideSelectedText"
      :custom-active-class="customActiveClass"
      :custom-dropdown-list-class="
        isMobileFilter
          ? 'vms-filters__dropdown--fullscreen vd-margin-top-0'
          : ''
      "
      :class="{ 'col-12 vd-margin-bottom-small': isMobileFilter }"
      @handle:filter-select="setProjectsFilterCookies"
    ></project-status-filter>
    <project-product-type-filter
      :hide-selected-text="hideSelectedText"
      :custom-active-class="customActiveClass"
      :custom-dropdown-list-class="
        isMobileFilter
          ? 'vms-filters__dropdown--fullscreen vd-margin-top-0'
          : ''
      "
      :class="{ 'col-12 vd-margin-bottom-small': isMobileFilter }"
      @handle:filter-select="setProjectsFilterCookies"
    ></project-product-type-filter>

    <div
      id="clearProjectsFilterDiv"
      class="vd-padding-small vms-filters__dropdown--list-options__clear vd-display-inline-block vd-margin-bottom-extra-small"
      @click="handleFilterClear"
    >
      <span class="vd-light-blue">Clear</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProjectTypeFilter from '@views/projects/projects-list/projects-list-filter/project-type-filter'
import ProjectCreatedFilter from '@views/projects/projects-list/projects-list-filter/project-created-filter'
import ProjectUserFilter from '@views/projects/projects-list/projects-list-filter/project-user-filter'
import ProjectStatusFilter from '@views/projects/projects-list/projects-list-filter/project-status-filter'
import ProjectProductTypeFilter from '@views/projects/projects-list/projects-list-filter/project-product-type-filter'
import ApiCustomIncludes from '@configs/api-custom-includes'
import VueCookies from 'vue-cookies'
import VmsConstants from '@configs/vms-constants'
import { find, size, includes } from 'lodash'

export default {
  components: {
    ProjectTypeFilter,
    ProjectCreatedFilter,
    ProjectUserFilter,
    ProjectStatusFilter,
    ProjectProductTypeFilter,
  },

  props: {
    hideSelectedText: {
      type: Boolean,
      required: false,
      default: false,
    },

    isMobileFilter: {
      type: Boolean,
      required: false,
      default: false,
    },

    customActiveClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getVmsUserData',
    }),
  },

  methods: {
    ...mapActions({
      updateProjectStatusFilter: 'projects/updateProjectStatusFilter',
      updateProjectProductTypeFilter: 'projects/updateProjectProductTypeFilter',
      updateProjectUserFilter: 'projects/updateProjectUserFilter',
      updateProjectTypeFilter: 'projects/updateProjectTypeFilter',
      updateProjectSearchKeyword: 'projects/updateProjectSearchKeyword',
      updateProjectFromDate: 'projects/updateProjectFromDate',
      updateProjectToDate: 'projects/updateProjectToDate',
      getAllProjects: 'projects/getAllProjects',
      setProjectsFilterCookies: 'projects/setProjectsFilterCookies',
      updateProjectPerPage: 'projects/updateProjectPerPage',
    }),

    handleFilterClear() {
      this.updateProjectStatusFilter([])
      this.updateProjectProductTypeFilter([])
      this.updateProjectUserFilter([])
      this.updateProjectSearchKeyword('')
      this.updateProjectFromDate('')
      this.updateProjectToDate('')
      this.updateProjectPerPage(25)

      const shouldShowMyProjects =
        size(
          find(this.loggedInUser.roles, (role) => {
            return includes(
              [
                VmsConstants.userRoles.USER_ROLE_ID_PRODUCER,
                VmsConstants.userRoles.USER_ROLE_ID_ACCOUNT_MANAGER,
              ],
              role.id
            )
          })
        ) > 0

      if (shouldShowMyProjects) {
        this.updateProjectTypeFilter('my_projects')
      } else {
        this.updateProjectTypeFilter('all_projects')
      }

      this.getAllProjects({
        include: ApiCustomIncludes.projectsList,
        page: 1,
      })

      this.clearFilterInCookies()
    },

    clearFilterInCookies() {
      VueCookies.remove('vmsx-dashboard-filters')
    },
  },
}
</script>
