<template>
  <div>
    <harvest-component
      :project="project"
      custom-parent-div-class="vms-dropdown__list vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-text-align-left"
    >
      <div slot="body" class="vd-display-inline">
        <span class="vms-dropdown__list--align-middle vd-icon-clock"></span>
        <span class="vms-dropdown__list--align-middle">Harvest</span>
      </div>
    </harvest-component>
    <archive-project-component
      v-if="showArchiveButton"
      :project="project"
      custom-parent-div-class="vms-dropdown__list vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-text-align-left"
      :update-project-list="true"
    >
      <div slot="body" class="vd-display-inline">
        <span class="vms-dropdown__list--align-middle vd-icon-archive"></span>
        <span class="vms-dropdown__list--align-middle">Archive</span>
      </div>
    </archive-project-component>
    <hold-project-component
      v-if="showHoldButton"
      :project="project"
      custom-parent-div-class="vms-dropdown__list vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-text-align-left"
      :update-project-list="true"
    >
      <div slot="body" class="vd-display-inline">
        <span
          :class="[
            'vms-dropdown__list--align-middle',
            'vd-icon-pause-circle-hover',
          ]"
        ></span>
        <span class="vms-dropdown__list--align-middle">Hold</span>
      </div>
    </hold-project-component>

    <unhold-project-component
      v-else-if="showUnholdButton"
      :project="project"
      custom-parent-div-class="vms-dropdown__list vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-text-align-left"
      :update-project-list="true"
    >
      <div slot="body" class="vd-display-inline">
        <span
          :class="[
            'vms-dropdown__list--align-middle',
            'vd-icon-pause-red-dot-circle-hover',
          ]"
        ></span>
        <span class="vms-dropdown__list--align-middle">Unhold</span>
      </div>
    </unhold-project-component>
  </div>
</template>

<script>
import HarvestComponent from '@components/harvest/harvest'
import ArchiveProjectComponent from '@components/archive-project/archive-project'
import HoldProjectComponent from '@components/hold-project/hold-project'
import UnholdProjectComponent from '@components/unhold-project/unhold-project'
import VmsConstants from '@configs/vms-constants'
import { mapGetters } from 'vuex'
import { isNil, find, includes } from 'lodash'

export default {
  components: {
    HarvestComponent,
    ArchiveProjectComponent,
    HoldProjectComponent,
    UnholdProjectComponent,
  },

  props: {
    project: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters({
      selectedFieldsForUpdating: 'projects/selectedFieldsForUpdating',
      editProjectLoading: 'project/editProjectLoading',
    }),

    showArchiveButton() {
      const isArchiveSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.project.id &&
            selectedField.field === 'status'
          )
        }
      )

      return !(
        this.project.project_status.id ===
          VmsConstants.projectStatuses.PROJECT_STATUS_ID_CANCELLED ||
        this.project.project_status.id ===
          VmsConstants.projectStatuses.PROJECT_STATUS_ID_ARCHIVED ||
        (!isNil(isArchiveSelected) && this.editProjectLoading) ||
        this.project.project_status.id ===
          VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT
      )
    },

    showHoldButton() {
      const isHoldSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.project.id &&
            selectedField.field === 'status'
          )
        }
      )

      return (
        isNil(isHoldSelected) &&
        !this.editProjectLoading &&
        !includes(
          [
            VmsConstants.projectStatuses.PROJECT_STATUS_ID_ON_HOLD,
            VmsConstants.projectStatuses.PROJECT_STATUS_ID_CANCELLED,
            VmsConstants.projectStatuses.PROJECT_STATUS_ID_ARCHIVED,
            VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT,
            VmsConstants.projectStatuses.PROJECT_STATUS_ID_APPROVED,
          ],
          this.project.project_status_id
        )
      )
    },

    showUnholdButton() {
      const isUnholdSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.project.id &&
            selectedField.field === 'status'
          )
        }
      )

      return (
        this.project.project_status_id ===
          VmsConstants.projectStatuses.PROJECT_STATUS_ID_ON_HOLD &&
        isNil(isUnholdSelected) &&
        !this.editProjectLoading
      )
    },
  },
}
</script>
