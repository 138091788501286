<template>
  <main-layout>
    <div
      slot="content-body"
      ref="vms-projects"
      :class="[
        'vms-projects__list--container',
        isMobileScreen
          ? 'vms-projects__list--container__mobile'
          : 'vms-content__container',
      ]"
    >
      <div
        class="vd-margin-left-medium vd-margin-right-medium vd-margin-top-small"
      >
        <div class="row no-gutters">
          <div class="col-12 vms-breadcrumb">
            <a
              id="viewHomePageLink"
              class="vd-a vd-icon-home"
              :href="homePageUrl"
            ></a>
            <span
              class="vd-light-grey vd-margin-right-extra-small vd-margin-left-extra-small"
            >
              |
            </span>
            <span class="vd-black">Projects</span>
          </div>
        </div>

        <h2 class="vd-light-blue vd-margin-top-medium vd-margin-bottom-medium">
          Projects
        </h2>
      </div>

      <projects-list
        v-if="!producersListLoading && !accountManagersListLoading"
        @update:pagination="scrollToTop"
      ></projects-list>

      <default-loader
        v-else
        class="vms-loader__visdom-shield--full"
      ></default-loader>
    </div>
  </main-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MainLayout from '@/layouts/main-layout'
import ProjectsList from '@views/projects/projects-list/projects-list'
import ApiCustomIncludes from '@configs/api-custom-includes'
import AppConfig from '@configs/app-config'
import { find, isNil, size, forEach } from 'lodash'
import DefaultLoader from '@components/loaders/default-loader'

export default {
  components: {
    MainLayout,
    ProjectsList,
    DefaultLoader,
  },

  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getVmsUserData',
      staffList: 'user/staffList',
      producers: 'user/producers',
      accountManagers: 'user/accountManagers',
      producersListLoading: 'user/producersListLoading',
      accountManagersListLoading: 'user/accountManagersListLoading',
      isMyProjectsFiltered: 'projects/isMyProjectsFiltered',
      defaultSelectedProjectType: 'projects/defaultSelectedProjectType',
      defaultSelectedCreatedDateFilter:
        'projects/defaultSelectedCreatedDateFilter',
      defaultSelectedUsersFilter: 'projects/defaultSelectedUsersFilter',
      defaultSelectedStatusFilter: 'projects/defaultSelectedStatusFilter',
      defaultSelectedProductTypesFilter:
        'projects/defaultSelectedProductTypesFilter',
      defaultSelectedPerPageFilter: 'projects/defaultSelectedPerPageFilter',
      defaultSelectedSortFilter: 'projects/defaultSelectedSortFilter',
      isMobileScreen: 'common/isMobileScreen',
    }),

    homePageUrl() {
      return `${AppConfig.vmsApiUrl}`
    },

    canCheckUserRole() {
      return size(this.producers) > 0 && size(this.accountManagers) > 0
    },
  },

  watch: {
    canCheckUserRole() {
      const isLoggedInUserInUsersList = find(this.staffList, (user) => {
        return user.id === this.loggedInUser.id
      })

      if (!isNil(isLoggedInUserInUsersList) || this.isMyProjectsFiltered) {
        this.updateProjectTypeFilter('my_projects')
      }

      this.applyDefaultSelectedFilters()

      this.getAllProjects({
        include: ApiCustomIncludes.projectDashboard,
      })
    },
  },

  mounted() {
    // By default remove all query on mounted.
    // This is because we don't have the meta info for the projects list so we cannot validate the page selected
    if (this.$route.query && this.$route.query.page) {
      this.$router.replace({
        query: {},
      })
    }

    this.getAccountManagers()
    this.getProducersList()
  },

  methods: {
    ...mapActions({
      getAllProjects: 'projects/getAllProjects',
      getProducersList: 'user/getProducersList',
      getAccountManagers: 'user/getAccountManagers',
      updateProjectPerPage: 'projects/updateProjectPerPage',
      updateProjectUserFilter: 'projects/updateProjectUserFilter',
      updateProjectTypeFilter: 'projects/updateProjectTypeFilter',
      updateProjectFromDate: 'projects/updateProjectFromDate',
      updateProjectToDate: 'projects/updateProjectToDate',
      updateProjectStatusFilter: 'projects/updateProjectStatusFilter',
      updateProjectProductTypeFilter: 'projects/updateProjectProductTypeFilter',
      updateProjectSort: 'projects/updateProjectSort',
      updateProjectSortBy: 'projects/updateProjectSortBy',
    }),

    scrollToTop() {
      this.$refs['vms-projects'].scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    },

    applyDefaultSelectedFilters() {
      if (this.defaultSelectedProjectType) {
        this.updateProjectTypeFilter(this.defaultSelectedProjectType)
      }

      if (this.defaultSelectedCreatedDateFilter) {
        this.updateProjectFromDate(this.defaultSelectedCreatedDateFilter.start)
        this.updateProjectToDate(this.defaultSelectedCreatedDateFilter.end)
      }

      if (this.defaultSelectedUsersFilter) {
        const usersSelected = []

        forEach(this.defaultSelectedUsersFilter, (selectedUserId) => {
          const user = find(this.staffList, (user) => {
            return user.id === selectedUserId
          })

          if (!isNil(user)) {
            usersSelected.push(user)
          }
        })

        this.updateProjectUserFilter(usersSelected)
      }

      if (this.defaultSelectedStatusFilter) {
        this.updateProjectStatusFilter(this.defaultSelectedStatusFilter)
      }

      if (this.defaultSelectedProductTypesFilter) {
        this.updateProjectProductTypeFilter(
          this.defaultSelectedProductTypesFilter
        )
      }

      if (this.defaultSelectedSortFilter) {
        this.updateProjectSortBy(this.defaultSelectedSortFilter.sortBy)
        this.updateProjectSort(this.defaultSelectedSortFilter.direction)
      }

      this.updateProjectPerPage(this.defaultSelectedPerPageFilter)
    },
  },
}
</script>
