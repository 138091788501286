<template>
  <filters-dropdown
    class="vd-margin-right-medium vd-padding-bottom-extra-small vd-display-inline-block"
    :custom-active-class="dropdownActiveClass"
    :custom-dropdown-list-class="customDropdownListClass"
    :hide-selected-text="hideSelectedText"
    :selected-text="selectedStatusesFilter.text"
    :has-action-buttons="true"
    @handle:apply-click="handleStatusUpdate"
    @handle:cancel-click="handleCancelClick"
  >
    <i
      slot="filter-icon"
      :class="[iconColor, 'vd-icon icon-clock vd-margin-right-small']"
    >
    </i>
    <div slot="filter-dropdown-list">
      <div class="vd-padding-small vms-filters__dropdown--list-options">
        <label for="allProjectStatusCheckbox">
          <input
            id="allProjectStatusCheckbox"
            v-model="isAllStatusSelected"
            type="checkbox"
            class="vd-margin-right-small"
            value="all"
            @change="handleAllStatusSelect"
          />
          <span>All Statuses</span>
        </label>
      </div>

      <div
        v-for="(status, index) in dropdownOptions"
        :key="index"
        class="vd-padding-small vms-filters__dropdown--list-options"
      >
        <input
          :id="`projectStatusFilterCheckbox-${index}`"
          v-model="selectedStatuses"
          type="checkbox"
          class="vd-margin-right-small"
          :value="status.id"
        />

        <label :for="`projectStatusFilterCheckbox-${index}`">
          <span>{{ status.text }}</span>
        </label>
      </div>
    </div>
  </filters-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersDropdown from '@components/filters/filter-dropdown'
import StatusesConfig from '@configs/statuses'
import { filter, isEmpty, forEach, map, size } from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    FiltersDropdown,
  },

  props: {
    hideSelectedText: {
      type: Boolean,
      required: false,
      default: false,
    },
    customActiveClass: {
      type: String,
      required: false,
      default: '',
    },
    customDropdownListClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      selectedStatuses: [],
      isAllStatusSelected: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedStatusesFilter: 'projects/selectedStatusesFilter',
      projectsFilter: 'projects/projectsFilter',
    }),

    dropdownOptions() {
      return filter(StatusesConfig.projectStatus, (status) => {
        return status.id !== 'default'
      })
    },

    iconColor() {
      return this.customActiveClass && size(this.projectsFilter.statuses) > 0
        ? 'vd-white'
        : 'vd-black'
    },

    dropdownActiveClass() {
      return size(this.projectsFilter.statuses) > 0
        ? this.customActiveClass
        : ''
    },
  },

  mounted() {
    if (!isEmpty(this.selectedStatusesFilter.selectedStatuses)) {
      forEach(this.selectedStatusesFilter.selectedStatuses, (status) => {
        this.selectedStatuses.push(status.id)
      })
    }

    this.isAllStatusSelected =
      this.selectedStatusesFilter.text === 'All Statuses'
  },

  methods: {
    ...mapActions({
      getAllProjects: 'projects/getAllProjects',
      updateProjectStatusFilter: 'projects/updateProjectStatusFilter',
    }),

    handleAllStatusSelect() {
      if (!this.isAllStatusSelected) {
        this.selectedStatuses = []
      } else {
        const defaultStatuses = filter(
          StatusesConfig.projectStatus,
          (status) => {
            return status.id !== 'default'
          }
        )

        this.selectedStatuses = map(defaultStatuses, 'id')
      }
    },

    handleCancelClick() {
      this.selectedStatuses = []

      forEach(this.selectedStatusesFilter.selectedStatuses, (status) => {
        this.selectedStatuses.push(status.id)
      })
    },

    handleStatusUpdate() {
      this.updateProjectStatusFilter(this.selectedStatuses)

      this.getAllProjects({
        include: ApiCustomIncludes.projectsList,
        filter: {
          status: this.selectedStatuses.join(','),
        },
        page: 1,
      })

      this.$emit('handle:filter-select', {
        type: 'statuses',
        value: this.selectedStatuses,
      })
    },
  },
}
</script>
