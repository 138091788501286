<template>
  <text-popover
    v-if="productTypeIcon"
    :tooltip-id="tooltipId"
    custom-tooltip-class="vms-projects__list--product-types-icon"
    :show-icon="false"
    :tooltip-icon="''"
    :tooltip-label="`<i class='vd-icon ${productTypeIcon.icon} vd-text-v-align-middle vd-h3'></i>`"
    :tooltip-text="productType.name"
    tooltip-placement="right"
  >
  </text-popover>
</template>

<script>
import { find, isEmpty } from 'lodash'
import ProductTypesConfig from '@configs/product-types'
import TextPopover from '@components/tooltips/text-popover'

export default {
  components: {
    TextPopover,
  },

  props: {
    projectId: {
      type: Number,
      required: true,
      default: null,
    },

    productType: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    tooltipId() {
      return !isEmpty(this.productType)
        ? `productType-${this.productType.id}-project-${this.projectId}`
        : ''
    },

    productTypeIcon() {
      return find(ProductTypesConfig.productTypes, {
        id: this.productType.id,
      })
    },
  },
}
</script>
