<template>
  <div
    :class="['vms-projects__list--notes', !notes ? 'vd-text-align-center' : '']"
  >
    <inline-edit-loader v-if="showInlineLoader"></inline-edit-loader>

    <text-inline-editor
      v-else
      input-name="projectsListNotes"
      input-label="Project Notes"
      input-type="textarea"
      :input-value="notes"
      :validation-rules="validationRules"
      custom-input-class="vms-projects__list--notes__input vd-padding-small vd-margin-top-20 vd-margin-right-20 vd-margin-left-20 vd-margin-bottom-20"
      @handle:inline-save="handleProjectNotesUpdate"
    >
      <div slot="input-body">
        <text-popover
          v-if="!notes"
          custom-tooltip-class="vms-projects__list--actions-icon"
          tooltip-text="Add project notes"
          tooltip-placement="right"
          tooltip-icon="vd-icon-pencil-circle"
          :tooltip-id="`editProjectNotesTooltip-${projectId}`"
        ></text-popover>

        <div v-else>
          <div v-if="!shouldUsePopover">
            {{ notes | truncateString(maxNotesLength) }}
          </div>

          <text-popover
            v-else
            :tooltip-id="`projectNotesTooltip-${projectId}`"
            custom-tooltip-class="vd-padding-small vd-margin-top-small"
            tooltip-trigger="hover"
            tooltip-placement="right"
            :tooltip-text="notes"
            :show-icon="false"
            :tooltip-label="tooltipLabel"
          >
          </text-popover>
        </div>
      </div>
    </text-inline-editor>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import FiltersMixin from '@mixins/filters-mixin'
import { size, find, isNil } from 'lodash'
import TextInlineEditor from '@components/inline-edit/text-inline-editor'
import ApiCustomIncludes from '@configs/api-custom-includes'
import InlineEditLoader from '@components/inline-edit/inline-edit-loader'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
    TextPopover,
    TextInlineEditor,
    InlineEditLoader,
  },

  mixins: [FiltersMixin],

  props: {
    projectId: {
      type: Number,
      required: true,
      default: null,
    },

    notes: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      maxNotesLength: 60,
    }
  },

  computed: {
    ...mapGetters({
      selectedProjectId: 'projects/selectedProjectId',
      editProjectLoading: 'project/editProjectLoading',
      selectedFieldsForUpdating: 'projects/selectedFieldsForUpdating',
    }),

    validationRules() {
      return {
        required,
        maxLength: maxLength(255),
      }
    },

    showInlineLoader() {
      const isNotesFieldSelected = find(
        this.selectedFieldsForUpdating,
        (selectedField) => {
          return (
            selectedField.project_id === this.projectId &&
            selectedField.field === 'notes'
          )
        }
      )

      return (
        this.editProjectLoading &&
        this.selectedProjectId === this.projectId &&
        !isNil(isNotesFieldSelected)
      )
    },

    shouldUsePopover() {
      return size(this.notes) > this.maxNotesLength
    },

    tooltipLabel() {
      return `<div>${this.$options.filters.truncateString(
        this.notes,
        this.maxNotesLength
      )}</div>`
    },
  },

  methods: {
    ...mapActions({
      inLineUpdateProjectDetails: 'projects/inLineUpdateProjectDetails',
    }),

    handleProjectNotesUpdate(projectNotes) {
      this.inLineUpdateProjectDetails({
        projectId: this.projectId,
        includes: ApiCustomIncludes.projectsList,
        selectedField: { project_id: this.projectId, field: 'notes' },
        payload: {
          notes: projectNotes,
        },
      })
    },
  },
}
</script>
