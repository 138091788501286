var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-modal',{class:[
    'show vd-text-align-left',
    _vm.isMobileScreen ? 'vms-modal--fullscreen' : '' ],attrs:{"modal-dialog-class":"modal-lg","content-class":"vd-background-white"}},[_c('div',{staticClass:"modal-header vms-modal__header",attrs:{"slot":"modal-header"},slot:"modal-header"},[_c('div',[_c('div',{staticClass:"vd-h4 vd-black"},[_c('i',{staticClass:"vd-icon icon-box-archive vd-margin-right-extra-small"}),_vm._v("Project Archive ")]),_c('div',{staticClass:"vd-h5 vd-dark-grey"},[_vm._v(" Before archiving the project please provide some additional information ")])]),_c('div',{staticClass:"vms-modal__header--close close vd-black"},[_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.handleCancel($event)}}},[_vm._v("x")])])]),_c('div',{staticClass:"modal-body",attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('div',{class:[
        'vd-field vd-required vd-margin-bottom-large',
        _vm.footagePolicy ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("How should we handle this projects footage?")]),_c('model-list-select',{attrs:{"list":_vm.footagePolicies,"option-value":"id","option-text":"name","placeholder":"Select footage policy"},model:{value:(_vm.footagePolicy),callback:function ($$v) {_vm.footagePolicy=$$v},expression:"footagePolicy"}})],1),(_vm.isLongTermArchive)?_c('div',{class:[
        'vd-field vd-required vd-margin-bottom-large',
        _vm.reason ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Provide reason for archiving:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reason),expression:"reason"}],staticClass:"vms-input__textarea vd-border-light-grey vd-border-radius-6 vd-padding-medium",attrs:{"placeholder":"Please explain why you need to archive this projects files, if you don't absolutely have to we would prefer to delete"},domProps:{"value":(_vm.reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reason=$event.target.value}}})]):_vm._e(),(_vm.isPolicyKeep)?_c('div',{class:[
        'vd-field vd-required vd-margin-bottom-large',
        _vm.reason ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("These assets will be used in the following project(s):")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reason),expression:"reason"}],staticClass:"vms-input__textarea vd-border-light-grey vd-border-radius-6 vd-padding-medium",attrs:{"placeholder":"Detail any projects you know of that will need to use this project's assets."},domProps:{"value":(_vm.reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reason=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"vd-margin-top-large"},[_c('em',{staticClass:"vd-red vd-h5"},[_vm._v(" * Archiving and deleting of footage will not occur for at least 3 months after project archival ")])])]),_c('div',{staticClass:"modal-footer",attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('div',{staticClass:"vd-text-align-right"},[_c('button',{staticClass:"vd-btn-small vd-btn-grey vd-margin-right-small",on:{"click":function($event){$event.preventDefault();return _vm.handleCancel($event)}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"vd-btn-small vd-background-red vd-border-red vd-white",attrs:{"disabled":_vm.isSaveDisabled},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.projectDetailsLoading ? 'Archiving...' : 'Archive')+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }