<template>
  <div class="vd-display-inline-block vd-position-relative">
    <i
      class="vd-icon icon-magnifying-glass vd-padding-small vd-padding-left-medium vd-position-absolute vd-dark-grey"
    ></i>

    <input
      id="searchInputBox"
      :class="[
        'vd-full-width vd-border-grey vd-background-white vd-margin-right-small vd-padding-small vd-border-radius-2 vd-border-width-1 vd-padding-left-extra-large',
        customClass,
      ]"
      type="text"
      :value="searchText"
      :placeholder="placeholder"
      @input="handleSearchInputEvent"
      @keyup.enter="handleSearchEnterEvent"
    />
  </div>
</template>
<script>
export default {
  props: {
    customClass: {
      type: String,
      required: false,
      default: '',
    },

    searchText: {
      type: String,
      required: false,
      default: '',
    },

    placeholder: {
      type: String,
      required: false,
      default: 'Search',
    },
  },

  methods: {
    handleSearchEnterEvent(event) {
      this.$emit('enter:search', event.target.value)
    },

    handleSearchInputEvent(event) {
      this.$emit('input:search', event.target.value)
    },
  },
}
</script>
