<template>
  <div>
    <div
      v-click-outside="closeModalOutside"
      :class="[
        'vms-filters__dropdown vd-position-relative vd-display-block',
        hideSelectedText ? 'vms-filters__dropdown--selected-text-hidden' : '',
      ]"
    >
      <div
        :class="[
          'vms-filters__dropdown--container vd-padding-small vd-border-radius-2 vd-border-width-1',
          customActiveClass || 'vd-background-white vd-border-grey',
        ]"
        @click="handleToggleDropdownList"
      >
        <slot name="filter-icon">
          <span
            v-if="selectedIcon"
            :class="[
              'vms-filters__dropdown__icons vd-margin-right-small vms-filters__align-middle',
              selectedIcon,
            ]"
          ></span>
        </slot>

        <span
          v-if="!hideSelectedText"
          class="vd-margin-right-small vms-filters__align-middle vms-filters__dropdown__label"
          >{{ selectedText }}</span
        >

        <span
          :class="[
            'vms-filters__dropdown__icons--arrow vms-filters__align-middle',
            customActiveClass
              ? 'vd-icon-arrow-down-white'
              : 'vd-icon-arrow-down-grey-black',
          ]"
        ></span>
      </div>

      <div
        v-if="showDropdownList && displayDropdownList"
        :class="[
          'vms-filters__dropdown--list vd-position-absolute vd-background-white vd-border-grey vd-margin-top-extra-small vd-border-width-1',
          customDropdownListClass,
        ]"
      >
        <div class="vms-filters__dropdown--list-options__wrapper">
          <slot name="filter-dropdown-header"></slot>

          <slot name="filter-dropdown-list">
            <div
              v-for="(option, index) in dropdownOptions"
              :key="index"
              class="vms-filters__dropdown--list-options vd-padding-small"
              @click="handleOptionSelect(option)"
            >
              <span>{{ option.text }}</span>
            </div>
          </slot>
        </div>

        <div
          v-if="hasActionButtons"
          class="vms-filters__dropdown--list-options__footer vd-text-align-right vd-padding-extra-small vd-border-grey"
        >
          <span
            v-if="showClearOption"
            class="vms-filters__dropdown--list-options__footer--clear vd-red vd-margin-right-small"
            @click="handleClear"
            >Clear</span
          >

          <button
            class="vd-btn-small vd-btn-grey vd-margin-extra-small"
            @click="handleCancelClick"
          >
            Cancel
          </button>
          <button
            class="vd-btn-small vd-btn-blue vd-margin-extra-small"
            @click="handleApplyClick"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },

  props: {
    selectedIcon: {
      type: String,
      required: false,
      default: '',
    },

    selectedText: {
      type: String,
      required: true,
      default: 'Select filter',
    },

    customDropdownListClass: {
      type: String,
      required: false,
      default: '',
    },

    displayDropdownList: {
      type: Boolean,
      required: false,
      default: true,
    },

    hasActionButtons: {
      type: Boolean,
      required: false,
      default: false,
    },

    showClearOption: {
      type: Boolean,
      required: false,
      default: false,
    },

    dropdownOptions: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },

    hideSelectedText: {
      type: Boolean,
      required: false,
      default: false,
    },

    customActiveClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      showDropdownList: false,
    }
  },

  methods: {
    closeModalOutside() {
      if (this.hasActionButtons) {
        this.$emit('handle:cancel-click')
      }
      this.showDropdownList = false
    },

    toggleShowDropdownList() {
      this.showDropdownList = !this.showDropdownList
    },

    handleToggleDropdownList() {
      if (this.hasActionButtons) {
        this.$emit('handle:cancel-click')
      }
      this.toggleShowDropdownList()
    },

    handleOptionSelect(option) {
      this.$emit('handle:option-select', option)

      if (!this.hasActionButtons) {
        this.toggleShowDropdownList()
      }
    },

    handleApplyClick() {
      this.$emit('handle:apply-click')
      this.toggleShowDropdownList()
    },

    handleCancelClick() {
      this.$emit('handle:cancel-click')
      this.toggleShowDropdownList()
    },

    handleClear() {
      this.$emit('handle:clear-click')
      this.toggleShowDropdownList()
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/filter-dropdown';
</style>
